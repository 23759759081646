import React from 'react'
import styled from 'styled-components';

const Modal_Slide_4_1 = () => {
    return (
        <>
            <Wrapper>
            <Subheading>제18조(총회의 의결사항)</Subheading>
                <Paragraph>총회는 다음 각 호의 사항을 의결한다.</Paragraph>
                <List>
                    <ListItem>1. 임원의 선출 및 해임에 관한 사항</ListItem>
                    <ListItem>2. 본 법인의 해산 및 정관 변경에 관한 사항</ListItem>
                    <ListItem>3. 기본재산의 처분 및 취득에 관한 사항</ListItem>
                    <ListItem>4. 예산 및 결산의 승인</ListItem>
                    <ListItem>5. 사업계획의 승인</ListItem>
                    <ListItem>6. 기타 중요사항</ListItem>
                </List>

                <Subheading>제19조(의결정족수)</Subheading>
                <Paragraph>총회는 정관에서 따로 정하는 사항을 제외하고는 재적 회원 과반수 출석으로 개회하고 출석 회원 과반수의 찬성으로 의결한다.</Paragraph>

                <Subheading>제20조(의결제척사유)</Subheading>
                <Paragraph>회원이 다음 각 호의 어느 하나에 해당하는 때에는 그 의결에 참여하지 못한다.</Paragraph>
                <List>
                    <ListItem>1. 임원의 선출 및 해임에 있어 자신에 관한 사항을 의결할 때</ListItem>
                    <ListItem>2. 금전 및 재산의 수수 또는 소송 등에 관련되는 사항으로서 자신과 본 법인의 이해가 상반될 때</ListItem>
                </List>

                <Paragraph>제20조의2 (의사록)</Paragraph>
                <Paragraph>총회의 의사에 관하여는 의사록을 작성하고 의장과 의장이 지명한 출석자 3인 이상이 기명날인한다.</Paragraph>
            </Wrapper>
        </>
    )
}

export default Modal_Slide_4_1

const Wrapper = styled.div`
  font-family: Arial, sans-serif;
  padding: 1vh;
  height: 95%;
`;

const Heading = styled.h2`
  font-size: 1.5em;
  font-weight: bold;
  padding: 3vh 0  4vh 0;
  
`;
const Subheading = styled.h3`
font-weight: bold;
text-align: left;
  font-size: 1em;
  
`;
const Paragraph = styled.p`
text-align: left;
  margin-bottom: 1%;
`;


const List = styled.ul`
  margin-bottom: 10px;
`;

const ListItem = styled.li`

font-size: 0.8em;
  margin-bottom: 10px;
text-align: left;
  list-style-type: none;
`;
const ModalContent = styled.div`
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 5px;
`;