import React from "react";
import styled from "styled-components";
import {
  DESKTOP,
  LAPTOP,
  MINMOBILE,
  MOBILE,
  TABLET,
} from "../styles/MediaQuery";
import logo from "../images/useImage/MainLogo_01_w.png";
import logo2 from "../images/useImage/MainLogo_01_b.png";
import logoMini from "../images/useImage/Main_boll_W.png";
import Nav from "./Nav";
import { useState } from "react";

import { FiMenu } from "react-icons/fi";
import { FiX } from "react-icons/fi";
import { Link } from "react-scroll";
import { Modal } from "components/Modal";
import { MyContext } from "./MyContext";
import Modal_Mob from "components/Modal_Mob";
const Headers = () => {

  //상태 관리 모바일 모달 값 


  const [navState, setNavState] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen_M, setIsOpen_M] = useState(false);
  //페이지 처리를 위해 
  const [currentSlide, setCurrentSlide] = useState(0);
  const NavSet = () => {
    if (!navState) {
      setNavState(true);
    } else {
      setNavState(false);
    }
  };

  const ModalSet = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const ModalSet_M = () => {
    if (isOpen_M) {
      setIsOpen_M(false);
    } else {
      setIsOpen_M(true);
    }
  };
  const handleCloseModal = () => {
    setIsOpen(false);
  };




  return (
    <>
      <HederWrap onClick={NavSet}  >
        <Modal isOpen={isOpen} onClose={handleCloseModal} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} />
        <MyContext.Provider value={{ isOpen_M, setIsOpen_M, onclose }}>
          <Modal_Mob />
        </MyContext.Provider>
        <HDcontainer>
          <HDwrapper>
            <HDgroupLeft></HDgroupLeft>
            <HDgroupCenter>
              <LogoHeader>
                <GroupMobLogo>
                  <img src={logo} alt="" />
                </GroupMobLogo>
              </LogoHeader>
              <MainNav>
                <MainNavUl>
                  {/* <Hidden>
                    <img src={logoMini} />
                  </Hidden> */}
                  <ul>
                    <li>
                      <span onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSesxdVWtAKm_IyVLXc-vIssPwYGoMfBLcY1rYTKYnPKo7v06Q/viewform', '_blank')}>
                        회원가입
                      </span>
                    </li>

                    <li>
                      <span onClick={ModalSet}>협회 정관</span>
                    </li>
                    <li>
                      <Link to="section1" spy={true} smooth={true}>
                        <span onClick={NavSet}>유튜브</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="section2" spy={true} smooth={true}>
                        <span onClick={NavSet}>협회소개</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="section3" spy={true} smooth={true}>
                        <span onClick={NavSet}>사업분야</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="section4" spy={true} smooth={true}>
                        <span onClick={NavSet}>소개</span>
                      </Link>
                    </li>



                  </ul>
                </MainNavUl>
              </MainNav>

              {/* <MainNav></MainNav> */}
            </HDgroupCenter>
            <HDgroupRight>
              <HDRightSide>
                {/* <ToggleMenu onClick={NavSet}> 토글 </ToggleMenu> */}
                {navState ? (
                  <FiMenu size={25} className="navbar__menu" onClick={NavSet} />
                ) : (
                  <FiX size={25} className="navbar__menu" onClick={NavSet} />
                )}

                <HDRightSideNav navState={navState}>
                  <HDRightSideNavDiv>
                    <a>
                      <img src={logo2} alt="" />
                    </a>
                  </HDRightSideNavDiv>
                  <MyContext.Provider value={{ isOpen_M, setIsOpen_M }}>
                    <Nav onClick={NavSet} setIsOpen_M={setIsOpen_M} ModalSet_M={ModalSet_M} />
                  </MyContext.Provider>
                  {/* <Navbar navbarLinks={navbarLinks} /> */}
                </HDRightSideNav>
              </HDRightSide>
            </HDgroupRight>
          </HDwrapper>
        </HDcontainer>
      </HederWrap>

    </>
  );
};

export default Headers;

const HederWrap = styled.header`
  position: absolute;
  z-index: 200;
  width: 100%;
  top: 0;
  left: 0;
  /* border-bottom: 1px solid #007bff; */

  @media ${DESKTOP} {
  }
`;

const HDcontainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;

  @media ${DESKTOP} {
    //1300
    margin-right: 20px;
  }
`;

const HDwrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20px;
  position: static !important;
  @media ${DESKTOP} {
    //1300
    display: block;
    padding-top: 0px;
  }
`;

const HDgroupLeft = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  @media ${DESKTOP} {
    //1300
    padding-top: 0px;
  }
`;
const HDgroupCenter = styled.div`
  width: 100%;
  padding-top: 1em;
  padding-left: 20px;
  padding-right: 20px;
`;

const LogoHeader = styled.div`
  text-align: center;
`;

const GroupWebLogo = styled.a`
  text-align: center;
  @media ${DESKTOP} {
    //1300
    max-width: 1200px;
    display: inline;
  }
  @media ${LAPTOP} {
    //1200
    max-width: 1140px;
    display: inline;
  }

  @media ${TABLET} {
    //992
    max-width: 960px;
    display: none;
  }

  @media ${MOBILE} {
    //768
    max-width: 720px;
    display: none;
  }
  @media ${MINMOBILE} {
    //576
    max-width: 540px;
    display: none;
  }
`;

const GroupMobLogo = styled.a`
  display: none;
  text-align: center;

  img {
    max-height: 70px;
  }

  @media ${DESKTOP} {
    //1300
    max-width: 1200px;
    display: none;
  }
`;

const MainNav = styled.nav`
  display: none;

  @media ${DESKTOP} {
    z-index: 20;
    padding-top: 0px;
    display: block;
  }
`;
const MainNavUl = styled.div`
  display: none;
  font-family: "Yun";
  @media ${DESKTOP} {
    padding-top: 25px;
    text-align: right;
    position: relative;
    display: inline;

    li {
      position: relative;
      display: inline-block;
      list-style-type: none;
      margin: 0;
      padding: 2vh;
      font-size: 1.5rem;
      color: white;
      float: right;
    }
  }
`;

const ToggleMenu = styled.a`
  ::before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #fff;
    position: absolute;
    top: 5px;
    left: 0;
  }

  ::after {
    content: "";
    display: block;
    width: 0;
    height: 1px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-60deg);
    transition: all 0.3s;
  }
`;

const HDgroupRight = styled.div`
  justify-content: flex-end;
      position: fixed;
    right: 3vh;
`;

const HDRightSide = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  align-items: center;

  :first-child {
    margin-left: 0;
  }
  margin-right: 0px;

  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }
  @media ${DESKTOP} {
    display: none;
  }
`;
const HDRightSideNav = styled.nav`
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(2, 0, 65, 0.1);
  position: fixed;
  z-index: 99998;
  visibility: hidden;
  left: 0;
  bottom: 0;
  width: 25vh;
  max-width: 80%;
  height: 100%;
  padding: 39px 22px 50px 22px;
  overflow: auto;
  transition: all 0.5s;
  visibility: visible;

  transform: ${(props) =>
    props.navState === true ? "translateX(-105%)" : "transform: translateX(0)"};

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  ul li {
    text-align: center;
    padding: 9px 0;
    position: relative;
    margin: 0;
    line-height: 2.9em;
  }

  ul li a {
    font-weight: 400;
    font-size: 13px;
    color: #020041;
    text-transform: uppercase;
    touch-action: manipulation;
  }
`;

const HDRightSideNavDiv = styled.div`

  margin-bottom: 50px;
  a {
    touch-action: manipulation;
  }
`;

const Hidden = styled.li`
  left: 0;
  img {
    max-height: 90px;
  }
  @media ${MINMOBILE} {
    display: none;
  }

  @media ${MOBILE} {
    display: none;
  }
`;
