import React from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import { DESKTOP } from "styles/MediaQuery";

const Slider = ({ imageSrc, filpped }) => {
  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  const renderContent = () => {
    return (
      <>
        <SlickWrap>
          <img src={imageSrc} alt="" className="slider__image" />
          <TextArea>
            <MainText>
              <MiniText>
                <b>B</b>IO<b>D</b>IVERSITY <b>C</b>ONSERVATION <b>A</b>
                SSOCIATION
              </MiniText>
              <h1>사단법인 생물다양성보전협회</h1>
            </MainText>
          </TextArea>
        </SlickWrap>
      </>
    );
  };

  return (
    <div className={inView ? "slider slider--zoom" : "slider"} ref={ref}>
      {renderContent()}
    </div>
  );
};

export default Slider;

// div {position:relative; width:100%; height:100vh; overflow:hidden;}
// div img {width:100%; height:100%}
// div:before {position:absolute; left:-50%; bottom:0; width:50%; height:30vh; border-bottom:100px solid #fff; border-left:0px solid transparent; border-right:510px solid transparent; content:'';}
// div:after {position:absolute; right:-50%; bottom:0; width:50%; height:30vh; border-bottom:100px solid #fff; border-right:0px solid transparent; border-left:510px solid transparent; content:'';}

const SlickWrap = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: black;
  opacity: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(60%);
  }

  ::before {
    position: absolute;
    left: -50%;
    bottom: 0;
    width: 100%;
    height: 30vh;
    border-bottom: 250px solid #f4ffe2;
    border-left: 0px solid transparent;
    border-right: 510px solid transparent;
    content: "";
  }

  ::after {
    position: absolute;
    right: -50%;
    bottom: 0;
    width: 100%;
    height: 30vh;
    border-bottom: 250px solid #f4ffe2;
    border-right: 0px solid transparent;
    border-left: 510px solid transparent;
    content: "";
  }

  @media ${DESKTOP} {
    height: 100vh;
    opacity: 1.8;
    img {
      object-fit: none;
      filter: brightness(98%);
    }

    ::before {
      position: absolute;
      left: -50%;
      bottom: 0;
      width: 100%;
      height: 30vh;
      border-bottom: 130px solid #f4ffe2;
      border-left: 0px solid transparent;
      border-right: 1000px solid transparent;
      content: "";
    }

    ::after {
      position: absolute;
      right: -50%;
      bottom: 0;
      width: 100%;
      height: 30vh;
      border-bottom: 130px solid #f4ffe2;
      border-right: 0px solid transparent;
      border-left: 1000px solid transparent;
      content: "";
    }
  }
`;

const TextArea = styled.div`
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100vh;
  padding: 18% 10%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  ::before {
    position: absolute;
    left: -50%;
    bottom: 0;
    width: 100%;
    height: 30vh;
    border-bottom: 250px solid #f4ffe2;
    border-left: 0px solid transparent;
    border-right: 510px solid transparent;
    content: "";
  }

  ::after {
    position: absolute;
    right: -50%;
    bottom: 0;
    width: 100%;
    height: 30vh;
    border-bottom: 250px solid #f4ffe2;
    border-right: 0px solid transparent;
    border-left: 510px solid transparent;
    content: "";
  }

  @media ${DESKTOP} {
    padding: 34% 10%;
  }
`;

const MainText = styled.div`
  justify-content: center;
  padding: 63% 0%;
  height: 100%;
  width: 100%;
  z-index: 3;
  font-size: 2em;

  h1 {
    color: #fff;

    font-size: 0.9em;
  }

  @media ${DESKTOP} {
    padding-top: 28%;
    h1 {
      color: #fff;
      font-size: 1em;
    }
  }
`;
const SubText = styled.div`
  padding-top: 25%;
  text-color: #fff;
  display: flex;
`;

const MiniText = styled.div`
  font-size: 0.5em;
  line-height: 1.5;
  margin-bottom: 20px;
  color: #fff;
  text-transform: uppercase;
  b {
    color: orange;
    font-weight: 800;
    font-size: 1.2em;
  }

  @media ${DESKTOP} {
    font-size: 1.2em;
    font-family: "Yun", sans-serif;
    /* color: #e4e8df; */
  }
`;
