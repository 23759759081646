import React from 'react'
import styled from 'styled-components';

const Modal_Slide_5_1 = () => {
    return (
        <>
            <Wrapper>
             
                <Subheading>제24조(정족수)</Subheading>
                <Paragraph>이사회는 재적 이사 과반수의 출석으로 개회하고 출석 이사 과반수의 찬성으로 의결한다.</Paragraph>

                <Subheading>제25조(서면결의 금지)</Subheading>
                <Paragraph>
                    이사회의 의결은 서면결의에 의할 수 없다. 다만 이사회에 부의할 사항 중 경미한 사항 또는 긴급을 요하는 사항에 관하여는 이를 서면으로 의결하도록 할 수 있다. 이 경우에 회장은 그 결과를 차기 이사회에 보고하여야 한다.
                    <br />
                    ② 제1항의 서면결의 사항에 대하여 재적 이사 과반수가 이사회에 부칠 것을 요구하는 때에는 회장은 이에 따라야 한다.
                </Paragraph>
            </Wrapper>

        </>
    )
}

export default Modal_Slide_5_1


const Wrapper = styled.div`
  font-family: Arial, sans-serif;
  padding: 1vh;
  height: 95%;
`;

const Heading = styled.h2`
  font-size: 1.5em;
  font-weight: bold;
  padding: 3vh 0  4vh 0;
  
`;
const Subheading = styled.h3`
font-weight: bold;
text-align: left;
  font-size: 1em;
  
`;
const Paragraph = styled.p`
text-align: left;
  margin-bottom: 1%;
`;


const List = styled.ul`
  margin-bottom: 10px;
`;

const ListItem = styled.li`

font-size: 0.8em;
  margin-bottom: 10px;
text-align: left;
  list-style-type: none;
`;