import React from 'react'
import styled from 'styled-components';

const Modal_Slide_3 = () => {
    return (
        <>
            <Wrapper>
                <Heading>제3장 임 원</Heading>

                <Subheading>제9조(임원의 종류 및 정수)</Subheading>
                <List>
                    <ListItem>① 법인은 다음 각 호의 임원을 둔다.</ListItem>
                    <ListItem>1. 회장 1명</ListItem>
                    <ListItem>2. 부회장 1명</ListItem>
                    <ListItem>3. 이사(회장, 부회장을 포함한다.) 5명 이상 15명 이하</ListItem>
                    <ListItem>4. 감사 2명 이내</ListItem>
                </List>

                <Subheading>제10조(임원의 선임)</Subheading>
                <List>
                    <ListItem>① 법인의 임원은 총회에서 선출한다. 다만, 회장은 이사 중에서 호선한다.</ListItem>
                    <ListItem>② 임기가 만료된 임원은 임기만료 2개월 이내에 후임자를 선출하여야 하며, 임원이 궐위된 경우에는 궐위된 날부터 2개월 이내에 후임자를 선출하여야 한다.</ListItem>
                    <ListItem>③ 임원선출이 있을 때에는 임원선출이 있는 날부터 3주 이내에 관할법원에 등기를 마친 후 주무관청에 통보하여야 한다.</ListItem>
                </List>
                <Subheading>제11조(임원의 해임)</Subheading>
                <Paragraph>임원이 다음 각 호의 어느 하나에 해당하는 행위를 한 때에는 총회의 의결을 거쳐 해임할 수 있다.</Paragraph>
                <List>
                    <ListItem>1. 본법인의 목적에 위배되는 행위</ListItem>
                    <ListItem>2. 임원간의 분쟁․회계부정 또는 현저한 부당행위</ListItem>
                    <ListItem>3. 본법인의 업무를 방해하는 행위</ListItem>
                </List>


            </Wrapper>

        </>
    )
}

export default Modal_Slide_3

const Wrapper = styled.div`
  font-family: Arial, sans-serif;
  padding: 1vh;
  height: 95%;
`;

const Heading = styled.h2`
  font-size: 1.5em;
  font-weight: bold;
  padding: 3vh 0  4vh 0;
  
`;
const Subheading = styled.h3`
font-weight: bold;
text-align: left;
  font-size: 1em;
  
`;

const Paragraph = styled.p`
text-align: left;
  margin-bottom: 1%;
`;

const List = styled.ul`
  margin-bottom: 1%;
`;

const ListItem = styled.li`

font-size: 0.8em;
  margin-bottom: 10px;
text-align: left;
  list-style-type: none;
`;
const ModalContent = styled.div`
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 5px;
`;