import React from 'react'
import styled from 'styled-components';

const Modal_Slide_3_1 = () => {
    return (
        <>
            <Wrapper>
                

                <Subheading>제12조(임원의 결격사유)</Subheading>
                <Paragraph>다음 각 호의 어느 하나에 해당하는 자는 임원이 될 수 없다.</Paragraph>
                <List>
                    <ListItem>1. 금치산자 또는 한정치산자</ListItem>
                    <ListItem>2. 파산자로서 복권이 되지 아니한 자</ListItem>
                    <ListItem>3. 법원의 판결 또는 다른 것에 의하여 자격이 상실 또는 정지된 자</ListItem>
                    <ListItem>4. 금고 이상의 실형의 선고를 받고 그 집행이 종료(집행이 종료된 것으로 보는 경우를 포함한다)되거나 집행이 면제된 날부터 3년이 지나지 아니한 자</ListItem>
                    <ListItem>5. 금고 이상의 형의 집행유예선고를 받고 그 유예기간 중에 있는 자</ListItem>
                </List>

                <Subheading>제13조(임원의 임기)</Subheading>
                <Paragraph>① 이사의 임기는 3년, 감사의 임기는 2년으로 하며 연임할 수 있다. 다만 보선임원의 임기는 전임자의 남은 임기로 한다.</Paragraph>
                <Paragraph>② 임원은 임기만료 후라도 후임자가 취임할 때까지는 임원으로 직무를 수행하게 할 수 있다.</Paragraph>

                <Subheading>제14조(임원의 직무)</Subheading>
                <Paragraph>① 회장은 법인을 대표하고 법인의 업무를 통할하며, 총회 및 이사회의 의장이 된다.</Paragraph>
                <Paragraph>② 이사는 이사회에 출석하여 법인의 업무에 관한 사항을 의결하며 이사회 또는 회장으로부터 위임받은 사항을 처리한다.</Paragraph>
                <Paragraph>③ 감사는 다음 각 호의 직무를 수행한다.</Paragraph>
                <List>
                    <ListItem>1. 법인의 재산상황을 감사하는 일</ListItem>
                    <ListItem>2. 총회 및 이사회의 운영과 그 업무에 관한 사항을 감사하는 일</ListItem>
                    <ListItem>3. 제1호 및 제2호의 감사결과 부정 또는 부당한 점이 있음을 발견한 때에는 이사회 또는 총회에 그 시정을 요구하고 주무관청에게 보고하는 일</ListItem>
                    <ListItem>4. 제3호의 시정요구 및 보고를 하기 위하여 필요한 때에는 총회 또는 이사회의 소집을 요구하는 일</ListItem>
                    <ListItem>5. 본회의 재산상황과 업무에 관하여 총회 및 이사회 또는 회장에게 의견을 진술하는 일</ListItem>
                </List>
            </Wrapper>

        </>
    )
}

export default Modal_Slide_3_1

const Wrapper = styled.div`
  font-family: Arial, sans-serif;
  padding: 1vh;
  height: 95%;
`;

const Heading = styled.h2`
  font-size: 1.5em;
  font-weight: bold;
  padding: 3vh 0  4vh 0;
  
`;
const Subheading = styled.h3`
font-weight: bold;
text-align: left;
  font-size: 1em;
  
`;

const Paragraph = styled.p`
text-align: left;
  margin-bottom: 1%;
`;

const List = styled.ul`
  margin-bottom: 1%;
`;

const ListItem = styled.li`

font-size: 0.8em;
  margin-bottom: 10px;
text-align: left;
  list-style-type: none;
`;

const ModalContent = styled.div`
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 5px;
`;