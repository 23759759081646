import React from "react";
import styled from "styled-components";
import infoBack from "../images/TestImage/bg-newsletter.jpg";
import infoBack2 from "../images/useImage/PullRiver.jpg";
import infoBack3 from "../images/useImage/나무사잇길.jpg";
import linewave from "../images/TestImage/wave-line-03.png";
import { DESKTOP } from "styles/MediaQuery";
import bollLogo from "../images/useImage/Main_boll_W.png";
const Section_Info = () => {
  return (
    <>
      <InfoWarp>
        <BlackWrap>
          <InfoContainer>
            <InfoContent>
              {/* <InfoLine> */}
              <HeadingForm>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>

                {/* <img className="line-heading" src={linewave} alt="IMG" /> */}
                {/* <div>
                  <b>첫째,</b> 자연과 그 자연의 일부로 살아가는 인류와의
                  공존입니다.
                </div>
                <div>
                  <b>둘째,</b> 자연과 인류의 절충된 발전과 관리입니다.
                </div>
                <div>
                  <b>셋째,</b> 보전과 복원을 통한 생물다양성의 증대입니다.
                </div>
                <div>
                  <b>넷째,</b> 생물다양성을 기반으로 한 새로운 환경정책입니다.
                </div>
                <div>
                  <b>다섯째,</b> 우리 모두에게 이로운 가치와 명분입니다.
                </div> */}
              </HeadingForm>
              <img src={bollLogo} alt="" />

              {/* </InfoLine> */}
            </InfoContent>
          </InfoContainer>
        </BlackWrap>
      </InfoWarp>
    </>
  );
};

export default Section_Info;

const InfoWarp = styled.div`
  background-image: url(${infoBack3});
  background-position: center center;
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: calc(20vh + 65px);

  @media ${DESKTOP} {
    padding-bottom: calc(20vh + 110px);
  }
`;

const BlackWrap = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 60vh;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  z-index: 20;

  ::after {
    content: "";
    background: black;
  }
  ::before {
    content: "";
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    );
  }
`;

const InfoContainer = styled.div`
  padding-top: 30vh;
  margin-right: auto;
  margin-left: auto;
  width: 100%;

  @media ${DESKTOP} {
    margin-top: calc(100vh / 100 * 20 * (-1));
    position: relative;
    z-index: 0;
    padding-top: calc(100vh / 100 * 20 + 80px);
    padding-bottom: 120px;
  }
`;

const InfoContent = styled.div`
  margin-right: 0;
  margin-left: 0;
  flex-wrap: wrap;
  img {
    width: 95px;
  }
`;

const InfoLine = styled.div`
  background-color: #fff;
  width: 100%;
  text-align: center;
  height: 100%;
  box-shadow: 0px 0px 10px 0px rgba(2, 0, 65, 0.1);
  position: relative;
  padding: 80px 10% 90px 10%;

  h3 {
    font-size: 1.5em;
    font-weight: bold;
  }

  div {
    position: relative;
    font-size: 0.9em;
    padding-top: 0.2em;
    padding-bottom: 0.8em;
  }
  b {
    font-weight: bold;
    font-size: 1.3em;
  }

  ::before {
    content: "";
    display: block;
    width: calc(100% - 34px);
    height: calc(100% - 34px);
    position: absolute;
    top: 17px;
    left: 17px;
    border: 1px solid #28c8db;
    pointer-events: none;
  }

  @media ${DESKTOP} {
    background-color: #fff;
    width: 40%;
    margin-right: auto;
    margin-left: auto;
    padding: 130px 50px 120px 50px;
    box-shadow: 0px 0px 65px 0px rgba(168, 82, 82, 0.26);
    position: relative;

    h3 {
      font-size: 2.8em;
      font-weight: bold;
    }
  }
`;

const HeadingForm = styled.div`
  color: #fff;
  h2 {
    z-index: 3;
    font-size: 1.4em;
    line-height: 1.2em;
  }
  h3 {
    font-size: 1.6em;
    line-height: 2.8em;
    color: #fff;
  }
  div {
    position: relative;
    font-size: 0.85em;
    padding-top: 1em;
    padding-bottom: 1em;
  }

  b {
    font-size: 1.2em;
    font-weight: bold;
  }
`;
