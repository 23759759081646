import React from "react";
import styled from "styled-components";
import { DESKTOP, MOBILE, TABLET } from "styles/MediaQuery";
import videoBanner from "../images/TestImage/video-banner.jpg";
import videoBanner2 from "../images/useImage/VideoBass.jpg";
import { GrPlay } from "react-icons/gr";
const Section_Video = () => {
  return (
    <>
      <SectionVidoHome>
        <VideoContainer>
          <h2>인류만이 지구의 주인이 아닙니다.</h2>
          <h3>
            <b>생물다양성을 기억해 주시고 응원해 주세요</b>
          </h3>
          <VideRayout>
            <img src={videoBanner2} alt="IMG" />
            <a href="https://www.youtube.com/results?search_query=%EC%83%9D%EB%AC%BC%EB%8B%A4%EC%96%91%EC%84%B1%EB%B3%B4%EC%A0%84%ED%98%91%ED%9A%8C">
              <GrPlay color="#ffff" />
            </a>
          </VideRayout>
        </VideoContainer>
      </SectionVidoHome>
    </>
  );
};

export default Section_Video;

const SectionVidoHome = styled.div`
  padding-bottom: calc(100vh / 100 * 20 + 100px);
  background-image: -webkit-linear-gradient(top, #f4ffe2, #fff);
  background-image: -o-linear-gradient(top, #f4ffe2, #fff);
  background-image: -moz-linear-gradient(top, #f4ffe2, #fff);
  background-image: linear-gradient(top, #f4ffe2, #fff);
  position: relative;
  padding-top: 180px;

  @media ${MOBILE} {
    padding-top: 80px;
  }
`;

const VideoContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;

  h2 {
    font-size: 25px;
    padding-bottom: 20px;
    text-align: center !important;
    line-height: 1.3;

    color: #333;
    margin-bottom: 0.75rem;
    font-family: "Yun", sans-serif;
  }

  h3 {
    font-size: 16px;
    padding-bottom: 20px;
    font-family: "Yun", sans-serif;
  }

  @media ${DESKTOP} {
    padding-right: 80px;
    padding-left: 80px;

    h2 {
      font-size: 30px;
      padding-bottom: 20px;
      text-align: center !important;
      line-height: 1.3;
      font-family: "Yun", sans-serif;
      color: #333;
      margin-bottom: 0.75rem;
    }
  }
`;

const VideRayout = styled.div`
  background-color: #f4ffe2;
  padding: 15px;
  box-shadow: 0px 0px 10px 0px rgba(2, 0, 65, 0.1);
  position: relative;

  a {
    font-size: 25px;
    color: #fff;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 74px;
    height: 74px;
    border-radius: 50%;
    background-color: white;

    opacity: 0.5;
    position: absolute;
    top: calc(50% - 37px);
    left: calc(50% - 37px);
    padding-left: 5px;
    padding-top: 2px;
    transition: all 0.3s;
  }

  i {
    font-family: "simple-line-icons";
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  }

  @media ${DESKTOP} {
    padding: 40px;
  }
`;
