import { createGlobalStyle } from "styled-components";
import reset from "styled-reset"; // style-reset 패키지
const GlobalStyles = createGlobalStyle` 
    ${reset}
    *,
*:before,
*:after {
  margin: 0px;
  padding: 0px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}
/* 슬라이드 캐로셀 */
.slick-slide {
	  display: inline-block;
  }

//  font-family: 'Yun';  


html {
  font-size: 15px;
  position: relative;
}

body {
  font-family: 'Yun';  
  font-weight: 400;
  color: #666;
  line-height: 1.8;
  height: 100%;
  height: 100%;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}


a {
  text-decoration: none;
}

/* Link 컴포넌트가 활성화되었을 때의 스타일 */
a.active {
  text-decoration: underline;
}

a:focus {
  outline: none;
}

a:hover {
  text-decoration: none;
}

h1{
  font-family: "210M", sans-serif;
}
h2,
h3,
h4,
h5,
h6 {
  font-family: "Yun", sans-serif;
  color: #333;
  margin-bottom: 0.75rem;
}
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
  margin-bottom: 0;
}

h1,
h2,
h3 {
  line-height: 1.3;
}

h4,
h5,
h6 {
  line-height: 1.4;
}

p {
  margin-bottom: 0.75rem;
}
p:last-child {
  margin-bottom: 0;
}

ul, ol {
  list-style-type: circle;
  padding-left: 18px;
  margin-bottom: 0.75rem;
}
ul:last-child, ol:last-child {
  margin-bottom: 0;
}

input,
textarea,
button {
  outline: none;
  min-width: 0;
}
input:focus,
textarea:focus,
button:focus {
  outline: none;
}
input::-ms-clear,
textarea::-ms-clear,
button::-ms-clear {
  display: none;
}

button {
  border: none;
  cursor: pointer;
}

iframe {
  border: none;
}

img {
  max-width: 100%;
  height: auto;
}


`;

export default GlobalStyles;
