import React from "react";
import styled from "styled-components";

import backImage from "../images/useImage/1_Section.png";
import Logo from "../images/useImage/MainLogo_01_w.png";
import Logo2 from "../images/useImage/PullLpgo.png";
import Logo3 from "../images/Logo/Logo_Bdca_Boll.png";

import { DESKTOP } from "styles/MediaQuery";
const LineSection_1 = () => {
  return (
    <>
      <Wrap id="section_Top">
        <WrapOapcity></WrapOapcity>
        <Ment>
          <img src={Logo2} alt="" />
          {/* <h1>BDCA</h1>
          <h4>BIO DIVERSITY CONSERVATION ASSOCIATION </h4>
          <h3>사단법인 생물다양성보전협회</h3> */}
        </Ment>
      </Wrap>
    </>
  );
};

export default LineSection_1;

const Wrap = styled.div`
  /* background-image: url(${backImage}); */
  height: 100vh;
  /* background: linear-gradient(
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 1) 100%
    ),
    url(${backImage});
  background-size: cover; */

  @media ${DESKTOP} {
    background-image: none;
    width: 100%;
  }
`;

const WrapOapcity = styled.div`
  width: 100%;
`;

const Ment = styled.div`
  padding-top: 20em;
  padding-bottom: 10em;
  width: 80%;
  margin: 0 auto;

  h1 {
    color: #01a0d8;
    font-size: 10vh;
    font-weight: bold;
  }

  img {
    max-width: 25vh;
  }

  @media ${DESKTOP} {
    max-width: 35vh;
    padding-top: 25em;
    padding-bottom: 10em;
    width: 80%;
    margin: 0 auto;
  }
`;
