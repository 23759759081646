import React from "react";
import Parcking from "../images/TestImage/fracking.jpg";
import styled from "styled-components";

import lastImge_1 from "../images/river_one.jpg";
import lastImge_2 from "../images/freeImage_1.jpg";
import lastImge_3 from "../images/freeImage_2.jpg";
import lastImge_4 from "../images/bass_pn.png";
import lastImge_5 from "../images/freeImage_5.jpg";
import lastImge_6 from "../images/freeImage_4.jpg";
import lastImge_7 from "../images/Illustrator_1.png";
import { DESKTOP } from "styles/MediaQuery";

const Section_Last = () => {
  return (
    <>
      <LastWarp>
        <LastContainer>
          <LastRow>
            <LastCol>
              <h3>
                <b>지구라는 삶의 터전</b>을 공유하는 인류와 자연의 모든 생물에게
                전합니다. 서로를 존중하고 아끼고 사랑하며 서로를 위해 지속적인
                발전을 할 수 있도록 포기하지 않고 노력 하겠습니다.
              </h3>
              <hr></hr>
            </LastCol>
            {/* <LastCol>
              <a href="#">
                <img src={Parcking} alt="IMG" />
                <h4 className="text-center">생물다양성 보전 &amp; 이용</h4>
              </a>
            </LastCol>
            <LastCol>
              <a href="#">
                <img src={lastImge_1} alt="" />
                <h4 className="text-center">생태계교란생물 퇴치 및 관리</h4>
              </a>
            </LastCol>
            <LastCol>
              <a href="#">
                <img src={lastImge_2} alt="" />
                <h4 className="text-center">
                  생물다양성 보전 &amp; 이용에 관한 교육과 홍보
                </h4>
              </a>
            </LastCol>
            <LastCol>
              <a href="#">
                <img src={lastImge_3} alt="" />
                <h4 className="text-center">
                  정부 및 지방자치단체 또는 공공기관으로부터 위탁받은 사업
                </h4>
              </a>
            </LastCol>
            <LastCol>
              <a href="#">
                <img src={lastImge_6} alt="" />
                <h4 className="text-center">
                  생물다양성 보전에 관한 국제적인 협력
                </h4>
              </a>
            </LastCol>
            <LastCol>
              <a href="#">
                <img src={lastImge_5} alt="" />
                <h4 className="text-center">자연생태계 모니터링</h4>
              </a>
            </LastCol> */}
          </LastRow>
        </LastContainer>
      </LastWarp>
    </>
  );
};

export default Section_Last;

const LastWarp = styled.div`
  padding-top: 90px;
  padding-bottom: 90px;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
`;

const LastContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
`;

const LastRow = styled.div`
  padding: 20px 0px;
  display: flex;

  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`;

const LastCol = styled.div`
  position: relative;
  padding-top: 8%;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

  h3 {
    line-height: 1.4em;
    font-size: 0.9em;
  }
  b {
    font-size: 1.2em;
    font-weight: bold;
  }

  a {
    text-decoration: none;
  }

  h4 {
    margin-bottom: 0;
    text-align: center !important;
    line-height: 1.4;
    font-family: "Yun", sans-serif;
    color: #333;
    font-size: 1.2rem;
  }

  @media ${DESKTOP} {
    line-height: 1.8em;
    font-size: 1.5em;
  }
`;
