import React from "react";
import styled from "styled-components";
import backImage from "../images/useImage/2_Section.png";
import videoBanner2 from "../images/useImage/VideoBass.jpg";
import { DESKTOP, LAPTOP, MOBILE, PULLWIDE, TABLET } from "styles/MediaQuery";
import { GrPlay } from "react-icons/gr";
const LineSection_2 = () => {
  return (
    <>
      <Wrap>
        <Bottom>
          <h1>인류만이 지구의 주인이 아닙니다.</h1>
        </Bottom>
        <TopMent>
          <h3>생물다양성을 기억해주시고 응원해 주세요.</h3>
        </TopMent>

        <VideRayout id="section1">
          <img src={videoBanner2} alt="IMG" />
          <a
            href="https://www.youtube.com/results?search_query=%EC%83%9D%EB%AC%BC%EB%8B%A4%EC%96%91%EC%84%B1%EB%B3%B4%EC%A0%84%ED%98%91%ED%9A%8C"
            target="_blank"
          >
            <GrPlay color="#ffff" />
          </a>
        </VideRayout>
      </Wrap>
    </>
  );
};

export default LineSection_2;

const Wrap = styled.div`
  z-index: -1;
  width: 90%;

  padding-top: 15vh;
  padding-bottom: 15vh;
  margin: 0 auto;
  /* background: linear-gradient(
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 1) 100%
    ),
    url(${backImage});
  background-size: cover; */
  @media ${MOBILE} {
    width: 100%;
  }

  @media ${TABLET} {
  }

  @media ${DESKTOP} {
    background-image: none;
  }

  @media ${PULLWIDE} {
    padding-top: 3vh;
    padding-bottom: 3vh;
  }
`;

const Bottom = styled.div`
  padding-top: 5vh;
  h1 {
    font-weight: bold;
    font-size: 1.5em;
    color: white;
  }
  color: white;

  @media ${MOBILE} {
    h1 {
      font-size: 2em;
      font-weight: bold;
      padding-bottom: 1.5vh;
    }
  }

  @media ${TABLET} {
    h1 {
      font-size: 2em;
      font-weight: bold;
      padding-bottom: 1.5vh;
    }
  }

  @media ${DESKTOP} {
    h1 {
      font-size: 2.8em;
      font-weight: bold;
      padding-bottom: 1.5vh;
    }
  }

  @media ${PULLWIDE} {
    padding-top: 0vh;
    padding-bottom: 0vh;
  }
`;

const TopMent = styled.div`
  padding-top: 1vh;
  h3 {
    color: white;
    font-size: 1.2em;
  }

  @media ${MOBILE} {
    padding-top: 10px;
    h3 {
      color: white;
      font-size: 1.2em;
    }
  }

  @media ${DESKTOP} {
    padding-top: 10px;
    h3 {
      color: white;
      font-size: 1.6em;
    }
  }
`;
const VideRayout = styled.div`
  /* background-color: #f4ffe2; */
  width: 95%;
  padding-top: 10vh;
  /* box-shadow: 0px 0px 10px 0px rgba(2, 0, 65, 0.1); */
  position: relative;

  a {
    font-size: 25px;
    color: #fff;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 74px;
    height: 74px;
    border-radius: 50%;
    background-color: white;

    opacity: 0.5;
    position: absolute;
    top: calc(68%);
    left: calc(50% - 37px);
    padding-left: 5px;
    padding-top: 2px;
    transition: all 0.3s;
  }

  i {
    font-family: "simple-line-icons";
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  }
  @media ${MOBILE} {
    padding: 15vh 10vh 10vh;
    width: 98%;
    margin: 0 auto;

    a {
      font-size: 25px;
      color: #fff;
      line-height: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 74px;
      height: 74px;
      border-radius: 50%;
      background-color: white;

      opacity: 0.5;
      position: absolute;
      top: calc(60%);
      left: calc(50% - 37px);
      padding-left: 5px;
      padding-top: 2px;
      transition: all 0.3s;
    }
  }

  @media ${TABLET} {
    padding: 10vh 10vh 10vh;
    width: 90%;
    margin: 0 auto;

    a {
      font-size: 25px;
      color: #fff;
      line-height: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 74px;
      height: 74px;
      border-radius: 50%;
      background-color: white;

      opacity: 0.5;
      position: absolute;
      top: calc(48%);
      left: calc(50% - 37px);
      padding-left: 5px;
      padding-top: 2px;
      transition: all 0.3s;
    }
  }

  @media ${DESKTOP} {
    padding: 10vh 10vh 10vh;
    width: 60%;
    margin: 0 auto;

    a {
      font-size: 25px;
      color: #fff;
      line-height: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 74px;
      height: 74px;
      border-radius: 50%;
      background-color: white;

      opacity: 0.5;
      position: absolute;
      top: calc(48%);
      left: calc(50% - 37px);
      padding-left: 5px;
      padding-top: 2px;
      transition: all 0.3s;
    }
  }

  @media ${PULLWIDE} {
    padding: 10vh 10vh 10vh;

    a {
      font-size: 25px;
      color: #fff;
      line-height: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 74px;
      height: 74px;
      border-radius: 50%;
      background-color: white;

      opacity: 0.5;
      position: absolute;
      top: calc(60%);
      left: calc(50% - 37px);
      padding-left: 5px;
      padding-top: 2px;
      transition: all 0.3s;
    }
  }
`;
