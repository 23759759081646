import React from "react";
import styled from "styled-components";

import slickIMG from "../images/useImage/slick_꿀벌.jpg";
import slickIMG_2 from "../images/useImage/slick_야광.jpg";
import slickIMG_3 from "../images/useImage/slick_새싹.jpg";
import slickIMG_4 from "../images/useImage/slick_트인강.jpg";
import slickIMG_5 from "../images/useImage/slick_상어비닐.jpg";
import slickIMG_6 from "../images/useImage/slick_새.jpg";
import slickIMG_7 from "../images/useImage/slick_수달.jpg";
import slickIMG_8 from "../images/useImage/slick_개울.jpg";
import slickIMG_9 from "../images/useImage/slick_백곰.jpg";
import { DESKTOP } from "styles/MediaQuery";

const Section_MiniImage = () => {
  return (
    <>
      <MiniWrap>
        <MiniContent>
          <MiniHeard>
            <h1> 우리가 하는일</h1>
            <h4>
              생물의 다양성을 보존하기위해 우리가 흘리는 땀이고 결실입니다
            </h4>
          </MiniHeard>
          {/* <MiniUl>
            <MiniLi>
              <LiDiv>
                <LiPicture>
                  <img src={slickIMG} alt="" />
                </LiPicture>
              </LiDiv>
            </MiniLi>
          </MiniUl> */}
          <FlexWrap>
            <DivMiniWrap>
              <img src={slickIMG} alt="" />
              <h3> 생물다양성 보전 및 이용에 관한 업무 </h3>
            </DivMiniWrap>
            <DivMiniWrap>
              <img src={slickIMG_2} alt="" />
              <h3> 생태계교란생물 퇴치 및 관리업무</h3>
            </DivMiniWrap>
          </FlexWrap>
          <FlexWrap>
            <DivMiniWrap>
              <img src={slickIMG_3} alt="" />
              <h3> 생물다양성 보전 및 이용에 관한 교육과 홍보 업무 </h3>
            </DivMiniWrap>
            <DivMiniWrap>
              <img src={slickIMG_4} alt="" />
              <h3> 정부 및 지방자치단체 또는 공공기관으로부터 위탁받은 사업</h3>
            </DivMiniWrap>
          </FlexWrap>{" "}
          <FlexWrap>
            <DivMiniWrap>
              <img src={slickIMG_5} alt="" />
              <h3> 생물다양성 보전에 관한 국제적인 협력업무</h3>
            </DivMiniWrap>
            <DivMiniWrap>
              <img src={slickIMG_6} alt="" />
              <h3> 멸종위기종 복원사업</h3>
            </DivMiniWrap>
          </FlexWrap>
          <FlexWrap>
            <DivMiniWrap>
              <img src={slickIMG_7} alt="" />
              <h3> 자연생태계 모니터링 업무 </h3>
            </DivMiniWrap>
            <DivMiniWrap>
              <img src={slickIMG_8} alt="" />
              <h3> 서식지외 보전기관 사업</h3>
            </DivMiniWrap>
          </FlexWrap>
          <FlexWrap>
            <DivPullWrap>
              <img src={slickIMG_9} alt="" />
              <h3>
                {" "}
                기타 본 법인의 발전과 목적 달성에 필요하다고 인정되는 사업{" "}
              </h3>
            </DivPullWrap>
          </FlexWrap>
        </MiniContent>
      </MiniWrap>
    </>
  );
};

export default Section_MiniImage;

const MiniWrap = styled.div`
  width: 100%;

  @media ${DESKTOP} {
    margin: 0 auto;
    width: 80%;
  }
`;

const MiniContent = styled.div``;

const MiniHeard = styled.div`
  //background-color: #00381e;
  width: 100%;
  padding: 5%;
  padding: 6rem 2rem 6rem;

  h1 {
    font-size: 1.6em;
    color: black;
    padding-bottom: 1rem;
  }
  h4 {
    font-size: 0.9em;
    color: black;
  }

  @media ${DESKTOP} {
    h1 {
      font-size: 2.5em;
      color: black;
      padding-bottom: 1rem;
    }
    h4 {
      font-size: 1.5em;
      color: black;
    }
  }
`;

const MiniUl = styled.ul`
  margin: 0 auto;
  margin-top: -120px;
`;

const MiniLi = styled.li`
  margin-bottom: 20px;
  margin-top: 0;
  padding: 0 20px;
`;

const LiDiv = styled.div`
  font-size: 30px;
  line-height: 22px;
  text-align: center;
`;

const LiPicture = styled.picture`
  display: block;
  text-align: center;
  width: 100%;

  img {
    line-height: 20px;
    text-indent: 10px;
    width: 450px;
    height: 225px;
  }
`;

const FlexWrap = styled.div`
  display: flex;
  justify-content: center;
  //  align-items: center;
`;

const DivMiniWrap = styled.div`
  display: block;
  width: 48%;
  padding: 3% 1%;
  font-size: 0.95em;

  h3 {
    font-weight: bold;
  }
  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 그림자 효과 설정 */
    z-index: -1;
  }

  @media ${DESKTOP} {
    width: 32%;
    padding: 3% 1%;
    font-size: 1.35em;

    h3 {
      font-weight: bold;
    }
  }
`;
const DivPullWrap = styled.div`
  display: block;
  width: 98%;
  padding: 1%;

  h3 {
    font-weight: bold;
  }

  @media ${DESKTOP} {
    width: 64%;
    padding: 3% 1%;
    font-size: 1.35em;

    h3 {
      font-weight: bold;
    }
  }
`;
