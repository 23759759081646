import React from 'react'
import styled from 'styled-components';

const Modal_Slide_7 = () => {
    return (
        <>

            <Wrapper>
                <Heading>제7장 사무부서</Heading>

                <Subheading>제34조(사무국)</Subheading>
                <Paragraph>
                    ① 본 법인의 사무를 처리하기 위하여 사무국을 둔다.
                    <br />
                    ② 사무국에 사무국장 1명과 필요한 직원을 둘 수 있다.
                    <br />
                    ③ 사무국장은 이사회의 의결을 거쳐 회장이 임명, 해임한다.
                    <br />
                    ④ 사무국의 조직 및 운영에 관한 사항은 이사회의 의결을 거쳐 별도로 정한다.
                </Paragraph>
            </Wrapper>

        </>
    )
}

export default Modal_Slide_7


const Wrapper = styled.div`
  font-family: Arial, sans-serif;
  padding: 1vh;
  height: 95%;
`;

const Heading = styled.h2`
  font-size: 1.5em;
  font-weight: bold;
  padding: 3vh 0  4vh 0;
  
`;
const Subheading = styled.h3`
font-weight: bold;
text-align: left;
  font-size: 1em;
  
`;
const Paragraph = styled.p`
text-align: left;
  margin-bottom: 1%;
`;


const List = styled.ul`
  margin-bottom: 0.3em;
`;

const ListItem = styled.li`

font-size: 0.8em;
  text-align: left;
  list-style-type: none;
`;