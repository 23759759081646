import React from 'react'
import styled from 'styled-components';

const Modal_Slide_6_1 = () => {
  return (
    <>
     <Wrapper>
     <Subheading>제29조(회계연도)</Subheading>
      <Paragraph>법인의 회계연도는 매년 1월 1일부터 12월 31일까지로 한다.</Paragraph>

      <Subheading>제30조(예산편성 및 결산)</Subheading>
      <Paragraph>
        ① 법인은 회계연도 1개월 전에 사업계획 및 예산안을 이사회의 의결을 거쳐 총회의 승인을 얻는다.
        <br />
        ② 법인은 사업실적 및 결산 내용을 당해 회계연도 종료 후 2개월 이내에 이사회의 의결을 거쳐 총회의 승인을 얻는다.
      </Paragraph>

      <Subheading>제31조(회계감사)</Subheading>
      <Paragraph>감사는 회계감사를 매년 실시하여야 한다.</Paragraph>

      <Subheading>제32조(임원의 보수)</Subheading>
      <Paragraph>임원에 대하여는 보수를 지급하지 아니한다. 다만, 업무수행에 필요한 실비는 예산의 범위 내에서 지급할 수 있다.</Paragraph>

      <Subheading>제33조(차입금)</Subheading>
      <Paragraph>법인이 예산 외의 의무부담이나 자금의 차입을 하고자 할 때에는 이사회의 의결을 거쳐야 한다.</Paragraph>
    </Wrapper>
    
    </>
  )
}

export default Modal_Slide_6_1


const Wrapper = styled.div`
  font-family: Arial, sans-serif;
  padding: 1vh;
  height: 95%;
`;

const Heading = styled.h2`
  font-size: 1.5em;
  font-weight: bold;
  padding: 3vh 0  4vh 0;
  
`;
const Subheading = styled.h3`
font-weight: bold;
text-align: left;
  font-size: 1em;
  
`;
const Paragraph = styled.p`
text-align: left;
  margin-bottom: 1%;
`;


const List = styled.ul`
  margin-bottom: 0.3em;
`;

const ListItem = styled.li`

font-size: 0.8em;
  text-align: left;
  list-style-type: none;
`;