import React from 'react'
import styled from 'styled-components';

const Modal_Slide_8 = () => {
    return (
        <>

            <Wrapper>
                <Heading>제8장 보 칙</Heading>

                <Subheading>제35조(법인해산)</Subheading>
                <Paragraph>
                    ① 법인을 해산하고자 할 때에는 총회에서 재적회원 4분의 3 이상의 찬성으로 의결하여 주무관청에 신고하여야 한다.
                    <br />
                    ② 법인의 해산 시 잔여재산은 총회 회의를 거처 주무관청의 허가를 받아 국가, 지방자치단체 또는 다른 유사한 목적을 가지는 단체에 귀속한다.
                </Paragraph>

                <Subheading>제36조(정관변경)</Subheading>
                <Paragraph>
                    이 정관을 변경하고자 할 때에는 재적이사 3분의 2 이상의 찬성과 총회에서 재적회원 3분의 2 이상의 찬성으로 의결하여 주무관청의 허가를 받아야 한다.
                </Paragraph>

                <Subheading>제37조(업무보고)</Subheading>
                <Paragraph>
                    다음 연도의 사업계획서 및 예산서와 해당연도 사업실적서 및 수지결산서는 회계연도 종료 후 2개월 이내에 주무관청에 보고하여야 한다. 이 경우 재산목록과 업무현황 및 감사결과 보고서도 함께 제출하여야 한다.
                </Paragraph>

                <Subheading>제38조(준용규정)</Subheading>
                <Paragraph>
                    이 정관에 규정되지 아니한 사항은 민법 중 사단법인에 관한 규정과 환경부 및 기상청 소관 비영리법인의 설립과 감독에 관한 규칙을 준용한다.
                </Paragraph>

                
            </Wrapper>

        </>
    )
}

export default Modal_Slide_8


const Wrapper = styled.div`
  font-family: Arial, sans-serif;
  padding: 1vh;
  height: 95%;
`;

const Heading = styled.h2`
  font-size: 1.5em;
  font-weight: bold;
  padding: 3vh 0  4vh 0;
  
`;
const Subheading = styled.h3`
font-weight: bold;
text-align: left;
  font-size: 1em;
  
`;
const Paragraph = styled.p`
text-align: left;
  margin-bottom: 1%;
`;


const List = styled.ul`
  margin-bottom: 0.3em;
`;

const ListItem = styled.li`

font-size: 0.8em;
  text-align: left;
  list-style-type: none;
`;