import React from "react";
import styled from "styled-components";

import backImage from "../images/useImage/3_Section.png";
import {
  DESKTOP,
  LAPTOP,
  MINMOBILE,
  MOBILE,
  PULLWIDE,
  TABLET,
} from "styles/MediaQuery";

const LineSection_3 = () => {
  return (
    <>
      <Wrap id="section2">
        <Main>
          <h1>협회 소개글</h1>
        </Main>
        <Content>
          <Bold>"창백한 푸른 점"</Bold>에 살고 있는 모든 생물은<br></br>
          저마다의 역할과 존재의 이유가 있습니다.<br></br>
          우리 인간 역시 그런 모든 생물과<br></br>
          지구라는 터전을 공유하고 있는 하나의 생물종 집단에 불과하다고
          생각합니다.<br></br>
          모든 생물과 생명체들은 더없이 소중하고 그 존재의 가치가 높다는 생각을
          <br></br>
          실질적인 행동으로 실천하고 생물 다양성을 회복, 유지, 관리하며<br></br>
          지구의 모든 생물들과의 조화로운 발전을 고민하고 행동하는 단체가
          되겠습니다.<br></br>
          “창백한 푸른 점”에 사는 모든 이웃을 아끼고 사랑하겠습니다.<br></br>
          우리가 살고 있는 지구의 가장 소중한 자산이자,<br></br>
          현재를 사는, 그리고 미래의 우리를 위한<br></br>
          생물 다양성을 기억해 주시고 응원해 주세요.<br></br>
          지구의 모든 생물들과 공존의 길을 함께 하겠습니다.<br></br>
        </Content>

        <Feat>생물다양성보전협회 임직원 일동</Feat>
      </Wrap>
    </>
  );
};

export default LineSection_3;

const Wrap = styled.div`
  padding-top: 10em;
  margin-top: -10px;
  height: 100vh;
  /* background: linear-gradient(
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 1) 100%
    ),
    url(${backImage});
  background-size: cover; */

  @media ${LAPTOP} {
    width: 80%;
    padding: 2em 0em;
    margin: 0 auto;
  }

  @media ${DESKTOP} {
    background-image: none;
    width: 80%;
    padding: 2em 0em;
    margin: 0 auto;
  }

  @media ${PULLWIDE} {
    background-image: none;
    width: 60%;
    padding: 2em 0em;
    margin: 0 auto;
  }
`;

const Main = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 0 auto;

  h1 {
    color: white;
    font-size: 1.15em;
    font-weight: bold;
    padding-bottom: 1.5em;
    text-align: left;
  }

  @media ${MINMOBILE} {
    h1 {
      color: white;
      font-size: 2.1em;
      font-weight: bold;
      padding-bottom: 1.35em;
      text-align: left;
    }
  }

  @media ${MOBILE} {
    h1 {
      color: white;
      font-size: 2.1em;
      font-weight: bold;
      padding-bottom: 1.35em;
      text-align: left;
    }
  }

  @media ${TABLET} {
    h1 {
      color: white;
      font-size: 2.8em;
      font-weight: bold;
      padding-bottom: 1.5em;
      text-align: left;
    }
  }

  @media ${LAPTOP} {
    h1 {
      color: white;
      font-size: 2.1em;
      font-weight: bold;
      padding-bottom: 1.5em;
      text-align: left;
    }
  }
  @media ${DESKTOP} {
    h1 {
      color: white;
      font-size: 2.1em;
      font-weight: bold;
      padding-bottom: 1.5em;
      text-align: left;
    }
  }
`;

const Content = styled.div`
  width: 80%;
  margin: 0 auto;
  font-size: 0.9em;
  color: white;
  text-align: left;

  @media ${MOBILE} {
    font-size: 1.1em;
    line-height: 5vh;
  }
  @media ${TABLET} {
    font-size: 1.1em;
    line-height: 5vh;
  }

  @media ${DESKTOP} {
    font-size: 1.2em;
    line-height: 5vh;
  }
`;

const Bold = styled.b`
  color: orange;
  font-size: 1.2em;
  font-weight: bold;
`;

const Feat = styled.div`
  padding-top: 1.3em;
  padding-left: 0.9em;
  padding-right: 0.9em;
  color: white;
  font-weight: bold;
  text-align: right;

  @media ${MINMOBILE} {
    padding-top: 3em;
    text-align: center;
    font-size: 1.3em;
  }

  @media ${MOBILE} {
  }

  @media ${DESKTOP} {
    text-align: left;
    font-size: 1.3em;
    padding-left: 12vh;
  }
`;
