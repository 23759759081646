import React, { useContext } from 'react';
import styled from "styled-components";
import Modal_Slide_1 from './Modal_Slide_1';
import Modal_slide_2 from './Modal_Slide_2';
import Modal_Slide_3 from './Modal_Slide_3';
import Modal_Slide_3_1 from './Modal_Slide_3_1';
import Modal_Slide_4 from './Modal_Slide_4';
import Modal_Slide_5 from './Modal_Slide_5';
import Modal_Slide_9 from './Modal_Slide_9';
import Modal_Slide_8 from './Modal_Slide_8';
import Modal_Slide_7 from './Modal_Slide_7';
import Modal_Slide_6 from './Modal_Slide_6';
import Modal_Slide_4_1 from './Modal_Slide_4_1';
import Modal_Slide_5_1 from './Modal_Slide_5_1';
import Modal_Slide_6_1 from './Modal_Slide_6_1';




export const Modal = ({ isOpen, onClose, currentSlide, setCurrentSlide }) => {
  

  if (!isOpen) return null;


  const slides = [<Modal_Slide_1 />, <Modal_slide_2 />, <Modal_Slide_3 />, <Modal_Slide_3_1 />, <Modal_Slide_4 />, <Modal_Slide_4_1 />, <Modal_Slide_5 />, <Modal_Slide_5_1 />, <Modal_Slide_6 />, <Modal_Slide_6_1 />, <Modal_Slide_7 />, <Modal_Slide_8 />, <Modal_Slide_9 />];

  const handlePrevSlide = () => {


    setCurrentSlide(nowPage => (nowPage === 0 ? slides.length - 1 : nowPage - 1));
  };

  const handleNextSlide = () => {
    setCurrentSlide(nowPage => (nowPage === slides.length - 1 ? 0 : nowPage + 1));
  };

  return (
    <>
      <ModalWrapper>
        <ModalContent>
          <SlideWrap>{slides[currentSlide]}</SlideWrap>
          <ModalBottom><button onClick={handlePrevSlide}>&lt;&lt;</button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button onClick={onClose}>닫기</button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button onClick={handleNextSlide}>&gt;&gt;</button>
          </ModalBottom>

        </ModalContent>
      </ModalWrapper>
    </>
  );
};


// Styled Components
const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 80%;
  
  background-color: black;
  /* rgba(0, 0, 0, 0.5); */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  
  
  overflow: hidden;
  border-radius: 5vh;
`;

const ModalContent = styled.div`
  background-color: #fff;
  
  border-radius: 4px;
  width: 100%;
  height: 80vh;
  padding: 20px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px;
`;

const ModalBottom = styled.div`
bottom:5%;
`;
const SlideWrap = styled.div`
height:95%
`

const PageOne = styled.div`
width: 100%;
height: 14vh;
`
const SlideButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  font-size: 20px;
  color: #999;
  cursor: pointer;
  padding: 0;
`;