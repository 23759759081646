import React from "react";
import styled from "styled-components";
import { RiYoutubeLine } from "react-icons/ri";
import logo from "../images/useImage/MainLogo_01_w.png";
import { DESKTOP } from "styles/MediaQuery";
const Footers = () => {
  return (
    <>
      <FooterWrap>
        <FContainer>
          <FContainerTextDiv>
            <FootersLogo>
              <a>
                <img src={logo} alt="IMG" />
              </a>
            </FootersLogo>

            <div className="hun-element-social layout-1">
              <a href="https://www.youtube.com/@bdca7404" target="_blank">
                <RiYoutubeLine color="#fff" size={35} />
              </a>
            </div>
          </FContainerTextDiv>
        </FContainer>
      </FooterWrap>
    </>
  );
};

export default Footers;

const FooterWrap = styled.footer`
  position: relative;
  z-index: 0;
  padding-top: 100px;
  padding-bottom: 80px;
  background-attachment: inherit;

  ::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #020041;
    opacity: 0.85;
  }

  @media ${DESKTOP} {
  padding: 8em ;
  }
`;

const FContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
`;

const FContainerTextDiv = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 1.8;
  color: #9999cc;
  word-break: break-word;
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
`;

const FootersLogo = styled.div`
  margin-bottom: 53px;
  margin: 0px;
  padding: 0px;

  img {
    width: 60%;
  }
  @media ${DESKTOP} {
  img {
    width: 60%;
  }
  }
`;
