import React from "react";

import helpers_logo1 from "../images/Logo/Logo_환경부.png";
import helpers_logo2 from "../images/Logo/Logo_한강유역환경청.png";
import helpers_logo3 from "../images/Logo/Logo_금강유역환경청.png";
import helpers_logo4 from "../images/Logo/Logo_영산강유역환경청.png";
import helpers_logo5 from "../images/Logo/Logo_낙동강유역환경청.png";
import helpers_logo6 from "../images/Logo/Logo_전북지방환경청.png";
import helpers_logo7 from "../images/Logo/Logo_대구지방환경청.png";
import helpers_logo8 from "../images/Logo/Logo_원주지방환경청.png";
import helpers_logo9 from "../images/Logo/Logo_행정안전부.png";
import helpers_logo10 from "../images/Logo/Logo_국립공원공단.png";
import helpers_logo11 from "../images/Logo/Logo_국립생태원.png";
import helpers_logo12 from "../images/Logo/Logo_Kwater.png";
import helpers_logo13 from "../images/Logo/Logo_한국농어촌공사.png";
import helpers_logo14 from "../images/Logo/Logo_충청북도.png";
import helpers_logo15 from "../images/Logo/Logo_충청남도.png";
import helpers_logo16 from "../images/Logo/Logo_청주시.png";
import helpers_logo17 from "../images/Logo/Logo_대한안전.png";
import helpers_logo18 from "../images/Logo/Logo_더블백.png";

import styled from "styled-components";
import { DESKTOP, LAPTOP, MOBILE, PULLWIDE, TABLET } from "styles/MediaQuery";

const Section_Helper = () => {
  return (
    <>
      {/* 
    1.환경부 
    2.한강유역환경청 
    3.금강유역환경청
    4.영산강유역환경청
    5.낙동강유역환경청
    6.전북지방환경청
    7.대구지방환경청
    8.원주지방환경청
    9.행정안전부 
    10.국립공원공단
    11.국립생태원
    12.케이워터
    13.한국농어촌공사
    14.충청북도
    15.충청남도
    16.청주시 
    17.대한안전연합
    18.더블케이 */}
      <MoveBtn>
        <button onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSesxdVWtAKm_IyVLXc-vIssPwYGoMfBLcY1rYTKYnPKo7v06Q/viewform', '_blank')}>회원가입</button>

      </MoveBtn>
      <LogoCharities>



        <LogoContainer>
          <h2 className="text-center">업무 협력 업체 </h2>
          <h4> 미래를위해, 우리를 위해 생물다양성보전을 함께하는 기관</h4>
          <br></br>
          <br></br>
          <LogoRow>
            <LogoWrap>
              <a
                href="http://me.go.kr/home/web/main.do"
                target="_blank"
                rel="noopener"
              >
                <img src={helpers_logo1} alt="IMG" />
              </a>
            </LogoWrap>
            <LogoWrap>
              <a
                href="https://www.me.go.kr/hg/web/main.do"
                target="_blank"
                rel="noopener"
              >
                <img src={helpers_logo2} alt="IMG" />
              </a>
            </LogoWrap>
            <LogoWrap>
              <a
                href="https://me.go.kr/gg/web/main.do"
                target="_blank"
                rel="noopener"
              >
                <img src={helpers_logo3} alt="IMG" />
              </a>
            </LogoWrap>
            <LogoWrap>
              <a
                href="http://me.go.kr/ysg/web/main.do"
                target="_blank"
                rel="noopener"
              >
                <img src={helpers_logo4} alt="IMG" />
              </a>
            </LogoWrap>
            <LogoWrap>
              <a
                href="http://me.go.kr/ndg/web/main.do"
                target="_blank"
                rel="noopener"
              >
                <img src={helpers_logo5} alt="IMG" />
              </a>
            </LogoWrap>
            <LogoWrap>
              <a
                href="https://me.go.kr/smg/web/main.do"
                target="_blank"
                rel="noopener"
              >
                <img src={helpers_logo6} alt="IMG" />
              </a>
            </LogoWrap>
            <LogoWrap>
              <a
                href="https://me.go.kr/daegu/web/main.do"
                target="_blank"
                rel="noopener"
              >
                <img src={helpers_logo7} alt="IMG" />
              </a>
            </LogoWrap>
            <LogoWrap>
              <a href="https://me.go.kr/wonju/" target="_blank" l="noopener">
                <img src={helpers_logo8} alt="IMG" />
              </a>
            </LogoWrap>
            <LogoWrap>
              <a
                href="https://www.mois.go.kr/frt/a01/frtMain.do"
                target="_blank"
                rel="noopener"
              >
                <img src={helpers_logo9} alt="IMG" />
              </a>
            </LogoWrap>

            <LogoWrap>
              <a href="http://knps.or.kr/" target="_blank" rel="noopener">
                <img src={helpers_logo10} alt="IMG" />
              </a>
            </LogoWrap>

            <LogoWrap>
              <a href="https://www.nie.re.kr/" target="_blank" rel="noopener">
                <img src={helpers_logo11} alt="IMG" />
              </a>
            </LogoWrap>

            <LogoWrap>
              <a
                href="https://www.kwater.or.kr/main.do?s_mid=1"
                target="_blank"
                rel="noopener"
              >
                <img src={helpers_logo12} alt="IMG" />
              </a>
            </LogoWrap>

            <LogoWrap>
              <a href="https://www.ekr.or.kr/" target="_blank" rel="noopener">
                <img src={helpers_logo13} alt="IMG" />
              </a>
            </LogoWrap>

            <LogoWrap>
              <a
                href="https://www.chungbuk.go.kr/www/index.do"
                target="_blank"
                rel="noopener"
              >
                <img src={helpers_logo14} alt="IMG" />
              </a>
            </LogoWrap>

            <LogoWrap>
              <a
                href="http://www.chungnam.go.kr/main.do"
                target="_blank"
                rel="noopener"
              >
                <img src={helpers_logo15} alt="IMG" />
              </a>
            </LogoWrap>

            <LogoWrap>
              <a
                href="https://www.cheongju.go.kr/www/index.do"
                target="_blank"
                rel="noopener"
              >
                <img src={helpers_logo16} alt="IMG" />
              </a>
            </LogoWrap>

            <LogoWrap>
              <a href="https://www.kshu.or.kr/" target="_blank" rel="noopener">
                <img src={helpers_logo17} alt="IMG" />
              </a>
            </LogoWrap>

            <LogoWrap>
              <a href="https://divingkk.com/" target="_blank" rel="noopener">
                <img src={helpers_logo18} alt="IMG" />
              </a>
            </LogoWrap>
          </LogoRow>
          <LogoPullDiv></LogoPullDiv>

          <LogoRow>
            {/* <LogoMiniDiv>
              <a href="#our-story"> 함께 하기 </a>
            </LogoMiniDiv> */}
          </LogoRow>
        </LogoContainer>
      </LogoCharities>
    </>
  );
};

export default Section_Helper;


const MoveBtn = styled.div`
height: 8vh;
top: 5vh;
//background-color : rgb(255,255,255,0.5);
position:relative;

button {
  
  background-color:  rgb(255,255,255,0.0); /* 푸른 색상 배경 */
  color: #fff; /* 흰색 텍스트 */
  /* padding: 10px 20px; */
  font-size: 1.5em;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3; /* 마우스 호버 시 색상 변경 */
}

`


const LogoCharities = styled.div`
  width: 100%;
  padding-top: 8vh;
  padding-bottom: 1px;
  color: white;

  @media ${TABLET} {
    padding-top: 25vh;
    width: 90%;
    margin: 0 auto;
  }

  @media ${LAPTOP} {
    padding-top: 25vh;
    width: 90%;
    margin: 0 auto;
  }

  @media ${DESKTOP} {
    padding-top: 15vh;
    width: 80%;
    margin: 0 auto;
  }

  @media ${PULLWIDE} {
    width: 80%;
    margin: 0 auto;
    padding-top: 12vh;
  }
`;

const LogoContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-right: 1vh;
  padding-left: 1vh;
  width: 100%;
  color: white;

  h2 {
    margin-bottom: 20px;
    text-align: center !important;
    line-height: 1.3;
    font-family: "Yun", sans-serif;
    color: white;
    font-size: 2.3rem;
    font-weight: 500;
  }
  @media ${DESKTOP} {
    h2 {
      color: white;
      font-weight: bold;
    }
  }
  h4 {
    color: white;
  }
`;

const LogoRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  justify-content: center;
  align-items: center;
`;

const LogoMiniDiv = styled.div`
  padding-bottom: 40px;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

  a {
    border-radius: 25px;
    border: none;
    background: #28c8db;
    color: #fff;
    height: 50px;

    font-family: "Yun", sans-serif;
    font-weight: bold;
    font-size: 15px;
    line-height: 1.5;
    letter-spacing: 1px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    min-width: 100px;
    padding: 0 15px;
    transition: all 0.2s;

    text-align: center !important;
  }
`;
const LogoPullDiv = styled.div`
  padding-bottom: 40px;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  @media ${DESKTOP} {
    width: 33%;
  }
`;

const LogoWrap = styled.div`
  position: relative;
  width: 100%;
  margin: 1vh;
  background-color: #fff;
  width: auto;

  img {
    padding: 1vh 1vh 0.1vh;
    max-width: 8rem;
    min-height: 2rem;
    justify-content: center;
    align-items: center;
  }
  @media ${MOBILE} {
    img {
      min-width: 15rem;
      max-height: 3rem;
      justify-content: center;
      align-items: center;
    }
  }

  @media ${TABLET} {
    max-height: 7vh;
    justify-content: center;
    align-items: center;
    img {
      min-width: 15rem;
      max-height: 3rem;
      justify-content: center;
      align-items: center;
    }
  }

  @media ${LAPTOP} {
    max-height: 7vh;
    justify-content: center;
    align-items: center;
    img {
      min-width: 15rem;
      max-height: 3rem;
      justify-content: center;
      align-items: center;
    }
  }
  @media ${DESKTOP} {
    max-height: 7vh;
    justify-content: center;
    align-items: center;
    img {
      min-width: 15rem;
      max-height: 3rem;
      justify-content: center;
      align-items: center;
    }
  }

  @media ${PULLWIDE} {
    padding: 1.2vh 1vh;
    justify-content: center;
    align-items: center;
    img {
      min-width: 15rem;
      max-height: 3rem;
      justify-content: center;
      align-items: center;
    }
  }
`;
