import React from 'react'
import styled from 'styled-components';

const Modal_Slide_9 = () => {
  return (
    <>

      <Wrapper>
        <Subheading>제39조(규칙제정)</Subheading>
        <Paragraph>
          이 정관이 정한 것 외에 본 법인의 운영에 관하여 필요한 사항은 이사회의 의결을 거쳐 규칙으로 정한다.
        </Paragraph>

        <Subheading>부 칙</Subheading>
        <Paragraph>
          제1조(시행일) 이 정관은 주무관청의 정관 변경 허가를 받은 날부터 시행한다.
          <br />
          제2조(기명날인) 이 정관은 2022년 11월 22일 총회를 통해 변경되었으며, 다음과 같이 의장 및 총회에 참석한 임원이 기명 날인했다.
        </Paragraph>
        <ParagraphRight>
          <h1>사단법인 생물다양성보전협회</h1>

          <b>이사(회장)</b> 공 명 식 (인) &nbsp; <b>이사(부회장)</b> 장 영 일 (인)
          <br />
          <b>이사</b> 변 화 근 (인) &nbsp;  <b>이사</b> 강 희 찬 (인)
          <br />
          <b>이사</b> 강 철 수 (인) &nbsp; <b>감사</b> 변 종 환 (인)
        </ParagraphRight>

        <TableWrapper>
          <Table>
            <TableHeading>
              <TableRow>
                <TableHeadingCell>지부명칭</TableHeadingCell>
                <TableHeadingCell>지부 주소</TableHeadingCell>
                <TableHeadingCell>지부장</TableHeadingCell>
              </TableRow>
            </TableHeading>
            <tbody>
              <TableRow>
                <TableCell>광주,전남지부</TableCell>
                <TableCell>전남 담양군 담양읍 지침3길 7-29 연빌라 나동 201호</TableCell>
                <TableCell>박상현</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>전북지부</TableCell>
                <TableCell>전북 장수군 계남면 동정길 25-41 </TableCell>
                <TableCell>성승일</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>대구,경북지부</TableCell>
                <TableCell>경북 포항시 북구 우창로 20 신동아베르디 201동 101호</TableCell>
                <TableCell>강정훈</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>제주지부</TableCell>
                <TableCell>제주특별자치도 제주시 신대로 18길 16, 1층 106호(연동,삼다맨션)</TableCell>
                <TableCell>황영수</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>대전,충남지부</TableCell>
                <TableCell>충남 홍성군 광천읍 홍남로 744번길 19-17</TableCell>
                <TableCell>권용규</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>강원지부</TableCell>
                <TableCell>강원도 화천군 사내면 사창리 310-4</TableCell>
                <TableCell>정문호</TableCell>
              </TableRow>
            </tbody>
          </Table>
        </TableWrapper>
      </Wrapper>

    </>
  )
}

export default Modal_Slide_9

const Wrapper = styled.div`
  font-family: Arial, sans-serif;
  padding: 1vh;
  height: 95%;
`;


const TableWrapper = styled.div`
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

const TableHeading = styled.thead`
  background-color: #f2f2f2;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const TableHeadingCell = styled.th`
  padding: 10px;
  text-align: center;
  font-weight: bold;
  border: 1px solid #ccc;
`;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ccc;
`;

const Paragraph = styled.p`
text-align: left;
  margin-bottom: 1%;
`;

const ParagraphRight = styled.p`
padding-right: 5%;
  text-align: right;
  margin-bottom: 1%;

  h1{
    text-align: right;
    line-height: 5vh;
  }
  b{
    font-weight: 600;
  }
`;



const Subheading = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;

  @media (max-width: 767px) {
    font-size: 18px;
  }
`;