import React from 'react'
import styled from 'styled-components'

export const Modal_slide_2 = () => {
  return (
    <>
      <Wrapper>
        <Heading>제2장 회 원</Heading>

        <Subheading>제5조(회원의 자격)</Subheading>
        <List>
          <ListItem>① 법인의 회원은 제2조의 목적사업에 찬성하여 소정의 가입절차를 마친 자(개인, 단체)로 한다.</ListItem>
          <ListItem>② 법인의 회원이 되고자 하는 자는 소정의 회원가입 신고서를 법인에 제출하여야 한다.</ListItem>
          <ListItem>③ 회원의 가입회비 등에 관한 세부사항은 총회에서 별도의 규정으로 정한다.</ListItem>
        </List>

        <Subheading>제6조(회원의 권리)</Subheading>
        <List>
          <ListItem>① 회원은 법인 임원 선거권 및 피선거권을 가지며 총회에 참석하여 법인의 활동에 관한 의견을 제안하고 의결에 참여할 권리를 가진다.</ListItem>
          <ListItem>② 회원은 법인의 자료 및 출판물을 제공받으며, 법인운영에 관한 자료를 열람할 수 있다.</ListItem>
        </List>

        <Subheading>제7조(회원의 의무)</Subheading>
        <List>
          <ListItem>회원은 다음 각 호의 의무를 진다.</ListItem>
          <ListItem>1. 본법인의 정관 및 제규정의 준수</ListItem>
          <ListItem>2. 총회 및 이사회의 결의사항 이행</ListItem>
          <ListItem>3. 회비 납부</ListItem>
        </List>

        <Subheading>제8조(회원의 탈퇴와 제명)</Subheading>
        <List>
          <ListItem>① 회원은 본인의 의사에 따라 회원탈퇴서를 제출함으로써 자유롭게 탈퇴할 수 있다.</ListItem>
          <ListItem>② 회원이 법인의 명예를 손상시키거나 목적 수행에 지장을 초래한 경우 또는 1년 이상 회원의 의무를 준수하지 않은 경우에는 총회의 의결을 거쳐 제명할 수 있다.</ListItem>
          <ListItem>③ 탈퇴 및 제명으로 인하여 회원의 자격을 상실한 경우에는 납부한 회비 등에 대한 권리를 요구할 수 없다.</ListItem>
        </List>


      </Wrapper>
    </>
  )
}
export default Modal_slide_2;

const Wrapper = styled.div`
  font-family: Arial, sans-serif;
  padding: 1vh;
  height: 95%;
`;

const Heading = styled.h2`
  font-size: 1.5em;
  font-weight: bold;
  padding: 3vh 0  4vh 0;
  
`;

const Subheading = styled.h3`
font-weight: bold;
text-align: left;
  font-size: 1em;
  
`;
const Paragraph = styled.p`
  margin-bottom: 10px;
`;

const List = styled.ul`

  margin-bottom: 10px;
`;

const ListItem = styled.li`

font-size: 0.8em;
  margin-bottom: 10px;
text-align: left;
  list-style-type: none;
`;

const ModalContent = styled.div`
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 5px;
`;
