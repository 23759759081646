import { MyContext } from 'layout/MyContext';
import React, { useContext } from 'react'
import styled from 'styled-components';

export const Modal_Mob = ({ onClose }) => {


    const { isOpen_M, setIsOpen_M } = useContext(MyContext);





    if (!isOpen_M) return null;



    return (
        <>
            <ModalWrapper>
                <ModalTop>
                    <BtnLight onClick={(event) => { event.stopPropagation(); setIsOpen_M(false) }} > X </BtnLight>

                </ModalTop>
                <ModalContent>
                    <Wrapper>
                        <Heading>사단법인 생물다양성 보전협회 정관</Heading>
                        <ParagraphRaght>제정 2014. 09. 18.</ParagraphRaght>
                        <ParagraphRaght>개정 2022. 11. 22.</ParagraphRaght>

                        <SubheadingMain>제1장 총 칙</SubheadingMain>
                        <Subheading>제1조(명칭)</Subheading>
                        <Paragraph>
                            본 법인의 명칭은 “사단법인 생물다양성보전협회”(이하 “법인”이라 한다)이라 한다.
                        </Paragraph>

                        <Subheading>제2조(목적)</Subheading>
                        <Paragraph>
                            이 법인은 생태계교란종 퇴치 및 생물다양성 보전 관리를 수행함으로써 정부의 생태계 보호 및 생물다양성 보전 관리 정책에 적극 기여함을 목적으로 한다.
                        </Paragraph>

                        <Subheading>제3조(사무소의 소재지)</Subheading>
                        <Paragraph>
                            ① 법인의 주사무소는 충청북도 청주시에 두며, 분사무소(지부)는 광역자치단체별로 추가하여 둘 수 있다.
                            <br />
                            ② 제1항에 따른 광역시.도 지부 및 시.군.구 지회의 설치.운영 등에 관한 구체적인 사항은 이사회의 의결로 정한다.
                        </Paragraph>

                        <Subheading>제4조(사업)</Subheading>
                        <Paragraph>
                            법인은 제2조의 목적을 달성하기 위하여 다음 각 호의 사업을 수행한다.
                            <br />
                            1. 생물다양성 보전 및 이용에 관한 업무
                            <br />
                            2. 생태계교란생물 퇴치 및 관리업무
                            <br />
                            3. 생물다양성 보전 및 이용에 관한 교육과 홍보 업무
                            <br />
                            4. 정부 및 지방자치단체 또는 공공기관으로부터 위탁받은 사업
                            <br />
                            5. 생물다양성 보전에 관한 국제적인 협력업무
                            <br />
                            6. 자연생태계 모니터링 업무
                            <br />
                            7. 멸종위기종 복원사업
                            <br />
                            8. 서식지외 보전기관 사업
                            <br />
                            9. 기타 본 법인의 발전과 목적 달성에 필요하다고 인정되는 사업
                        </Paragraph>

                        <Heading>제2장 회 원</Heading>

                        <Subheading>제5조(회원의 자격)</Subheading>
                        <List>
                            <ListItem>① 법인의 회원은 제2조의 목적사업에 찬성하여 소정의 가입절차를 마친 자(개인, 단체)로 한다.</ListItem>
                            <ListItem>② 법인의 회원이 되고자 하는 자는 소정의 회원가입 신고서를 법인에 제출하여야 한다.</ListItem>
                            <ListItem>③ 회원의 가입회비 등에 관한 세부사항은 총회에서 별도의 규정으로 정한다.</ListItem>
                        </List>

                        <Subheading>제6조(회원의 권리)</Subheading>
                        <List>
                            <ListItem>① 회원은 법인 임원 선거권 및 피선거권을 가지며 총회에 참석하여 법인의 활동에 관한 의견을 제안하고 의결에 참여할 권리를 가진다.</ListItem>
                            <ListItem>② 회원은 법인의 자료 및 출판물을 제공받으며, 법인운영에 관한 자료를 열람할 수 있다.</ListItem>
                        </List>

                        <Subheading>제7조(회원의 의무)</Subheading>
                        <List>
                            <ListItem>회원은 다음 각 호의 의무를 진다.</ListItem>
                            <ListItem>1. 본법인의 정관 및 제규정의 준수</ListItem>
                            <ListItem>2. 총회 및 이사회의 결의사항 이행</ListItem>
                            <ListItem>3. 회비 납부</ListItem>
                        </List>

                        <Subheading>제8조(회원의 탈퇴와 제명)</Subheading>
                        <List>
                            <ListItem>① 회원은 본인의 의사에 따라 회원탈퇴서를 제출함으로써 자유롭게 탈퇴할 수 있다.</ListItem>
                            <ListItem>② 회원이 법인의 명예를 손상시키거나 목적 수행에 지장을 초래한 경우 또는 1년 이상 회원의 의무를 준수하지 않은 경우에는 총회의 의결을 거쳐 제명할 수 있다.</ListItem>
                            <ListItem>③ 탈퇴 및 제명으로 인하여 회원의 자격을 상실한 경우에는 납부한 회비 등에 대한 권리를 요구할 수 없다.</ListItem>
                        </List>


                        <Subheading>제12조(임원의 결격사유)</Subheading>
                        <Paragraph>다음 각 호의 어느 하나에 해당하는 자는 임원이 될 수 없다.</Paragraph>
                        <List>
                            <ListItem>1. 금치산자 또는 한정치산자</ListItem>
                            <ListItem>2. 파산자로서 복권이 되지 아니한 자</ListItem>
                            <ListItem>3. 법원의 판결 또는 다른 것에 의하여 자격이 상실 또는 정지된 자</ListItem>
                            <ListItem>4. 금고 이상의 실형의 선고를 받고 그 집행이 종료(집행이 종료된 것으로 보는 경우를 포함한다)되거나 집행이 면제된 날부터 3년이 지나지 아니한 자</ListItem>
                            <ListItem>5. 금고 이상의 형의 집행유예선고를 받고 그 유예기간 중에 있는 자</ListItem>
                        </List>

                        <Subheading>제13조(임원의 임기)</Subheading>
                        <Paragraph>① 이사의 임기는 3년, 감사의 임기는 2년으로 하며 연임할 수 있다. 다만 보선임원의 임기는 전임자의 남은 임기로 한다.</Paragraph>
                        <Paragraph>② 임원은 임기만료 후라도 후임자가 취임할 때까지는 임원으로 직무를 수행하게 할 수 있다.</Paragraph>

                        <Subheading>제14조(임원의 직무)</Subheading>
                        <Paragraph>① 회장은 법인을 대표하고 법인의 업무를 통할하며, 총회 및 이사회의 의장이 된다.</Paragraph>
                        <Paragraph>② 이사는 이사회에 출석하여 법인의 업무에 관한 사항을 의결하며 이사회 또는 회장으로부터 위임받은 사항을 처리한다.</Paragraph>
                        <Paragraph>③ 감사는 다음 각 호의 직무를 수행한다.</Paragraph>
                        <List>
                            <ListItem>1. 법인의 재산상황을 감사하는 일</ListItem>
                            <ListItem>2. 총회 및 이사회의 운영과 그 업무에 관한 사항을 감사하는 일</ListItem>
                            <ListItem>3. 제1호 및 제2호의 감사결과 부정 또는 부당한 점이 있음을 발견한 때에는 이사회 또는 총회에 그 시정을 요구하고 주무관청에게 보고하는 일</ListItem>
                            <ListItem>4. 제3호의 시정요구 및 보고를 하기 위하여 필요한 때에는 총회 또는 이사회의 소집을 요구하는 일</ListItem>
                            <ListItem>5. 본회의 재산상황과 업무에 관하여 총회 및 이사회 또는 회장에게 의견을 진술하는 일</ListItem>
                        </List>


                        <Heading>제3장 임 원</Heading>

                        <Subheading>제9조(임원의 종류 및 정수)</Subheading>
                        <List>
                            <ListItem>① 법인은 다음 각 호의 임원을 둔다.</ListItem>
                            <ListItem>1. 회장 1명</ListItem>
                            <ListItem>2. 부회장 1명</ListItem>
                            <ListItem>3. 이사(회장, 부회장을 포함한다.) 5명 이상 15명 이하</ListItem>
                            <ListItem>4. 감사 2명 이내</ListItem>
                        </List>

                        <Subheading>제10조(임원의 선임)</Subheading>
                        <List>
                            <ListItem>① 법인의 임원은 총회에서 선출한다. 다만, 회장은 이사 중에서 호선한다.</ListItem>
                            <ListItem>② 임기가 만료된 임원은 임기만료 2개월 이내에 후임자를 선출하여야 하며, 임원이 궐위된 경우에는 궐위된 날부터 2개월 이내에 후임자를 선출하여야 한다.</ListItem>
                            <ListItem>③ 임원선출이 있을 때에는 임원선출이 있는 날부터 3주 이내에 관할법원에 등기를 마친 후 주무관청에 통보하여야 한다.</ListItem>
                        </List>
                        <Subheading>제11조(임원의 해임)</Subheading>
                        <Paragraph>임원이 다음 각 호의 어느 하나에 해당하는 행위를 한 때에는 총회의 의결을 거쳐 해임할 수 있다.</Paragraph>
                        <List>
                            <ListItem>1. 본법인의 목적에 위배되는 행위</ListItem>
                            <ListItem>2. 임원간의 분쟁․회계부정 또는 현저한 부당행위</ListItem>
                            <ListItem>3. 본법인의 업무를 방해하는 행위</ListItem>
                        </List>

                        <Heading>제4장 총 회</Heading>

                        <Subheading>제15조(총회의 구성)</Subheading>
                        <Paragraph>① 총회는 본회의 최고의결기관이며 회원으로 구성한다.</Paragraph>

                        <Subheading>제16조(총회의 구분과 소집)</Subheading>
                        <Paragraph>① 총회는 정기총회와 임시총회로 구분하며, 회장이 소집한다.</Paragraph>
                        <Paragraph>② 정기총회는 매 회계연도 개시 3개월 전, 후까지 소집하며, 임시총회는 회장이 필요하다고 인정할 때에 소집한다.</Paragraph>
                        <Paragraph>③ 총회의 소집은 회장이 회의안건, 일시, 장소 등을 명기하여 회의 개시 7일 전까지 문서, 문자메세지, email, sns 등 (발송 사실 확인이 되는 온라인 방법 포함)의 방법으로 각 회원에게 통지하여야 한다.</Paragraph>

                        <Subheading>제17조(총회소집의 특례)</Subheading>
                        <Paragraph>① 회장은 다음 각 호의 어느 하나에 해당하는 소집요구가 있을 때에는 그 소집 요구일로부터 20일 이내에 총회를 소집하여야 한다.</Paragraph>
                        <List>
                            <ListItem>1. 재적 이사 과반수가 회의의 목적을 제시하여 소집을 요구한 때</ListItem>
                            <ListItem>2. 제14조 제3항 제4호의 규정에 따라 감사가 소집을 요구한 때</ListItem>
                            <ListItem>3. 재적 회원 3분의 1 이상이 회의의 목적을 제시하여 소집을 요구한 때</ListItem>
                        </List>
                        <Paragraph>② 총회 소집권자가 궐위되거나 이를 기피함으로써 7일 이상 총회소집이 불가능한 때에는 재적 이사 과반수 또는 재적 회원 3분의 1 이상의 찬성으로 총회를 소집할 수 있다.</Paragraph>
                        <Paragraph>③ 제2항의 규정에 따른 총회는 출석 이사 중 최연장자의 사회아래 그 의장을 선출한다.</Paragraph>


                        <Subheading>제18조(총회의 의결사항)</Subheading>
                        <Paragraph>총회는 다음 각 호의 사항을 의결한다.</Paragraph>
                        <List>
                            <ListItem>1. 임원의 선출 및 해임에 관한 사항</ListItem>
                            <ListItem>2. 본 법인의 해산 및 정관 변경에 관한 사항</ListItem>
                            <ListItem>3. 기본재산의 처분 및 취득에 관한 사항</ListItem>
                            <ListItem>4. 예산 및 결산의 승인</ListItem>
                            <ListItem>5. 사업계획의 승인</ListItem>
                            <ListItem>6. 기타 중요사항</ListItem>
                        </List>

                        <Subheading>제19조(의결정족수)</Subheading>
                        <Paragraph>총회는 정관에서 따로 정하는 사항을 제외하고는 재적 회원 과반수 출석으로 개회하고 출석 회원 과반수의 찬성으로 의결한다.</Paragraph>

                        <Subheading>제20조(의결제척사유)</Subheading>
                        <Paragraph>회원이 다음 각 호의 어느 하나에 해당하는 때에는 그 의결에 참여하지 못한다.</Paragraph>
                        <List>
                            <ListItem>1. 임원의 선출 및 해임에 있어 자신에 관한 사항을 의결할 때</ListItem>
                            <ListItem>2. 금전 및 재산의 수수 또는 소송 등에 관련되는 사항으로서 자신과 본 법인의 이해가 상반될 때</ListItem>
                        </List>

                        <Paragraph>제20조의2 (의사록)</Paragraph>
                        <Paragraph>총회의 의사에 관하여는 의사록을 작성하고 의장과 의장이 지명한 출석자 3인 이상이 기명날인한다.</Paragraph>

                        <Heading>제4장 총 회</Heading>

                        <Subheading>제15조(총회의 구성)</Subheading>
                        <Paragraph>① 총회는 본회의 최고의결기관이며 회원으로 구성한다.</Paragraph>

                        <Subheading>제16조(총회의 구분과 소집)</Subheading>
                        <Paragraph>① 총회는 정기총회와 임시총회로 구분하며, 회장이 소집한다.</Paragraph>
                        <Paragraph>② 정기총회는 매 회계연도 개시 3개월 전, 후까지 소집하며, 임시총회는 회장이 필요하다고 인정할 때에 소집한다.</Paragraph>
                        <Paragraph>③ 총회의 소집은 회장이 회의안건, 일시, 장소 등을 명기하여 회의 개시 7일 전까지 문서, 문자메세지, email, sns 등 (발송 사실 확인이 되는 온라인 방법 포함)의 방법으로 각 회원에게 통지하여야 한다.</Paragraph>

                        <Subheading>제17조(총회소집의 특례)</Subheading>
                        <Paragraph>① 회장은 다음 각 호의 어느 하나에 해당하는 소집요구가 있을 때에는 그 소집 요구일로부터 20일 이내에 총회를 소집하여야 한다.</Paragraph>
                        <List>
                            <ListItem>1. 재적 이사 과반수가 회의의 목적을 제시하여 소집을 요구한 때</ListItem>
                            <ListItem>2. 제14조 제3항 제4호의 규정에 따라 감사가 소집을 요구한 때</ListItem>
                            <ListItem>3. 재적 회원 3분의 1 이상이 회의의 목적을 제시하여 소집을 요구한 때</ListItem>
                        </List>
                        <Paragraph>② 총회 소집권자가 궐위되거나 이를 기피함으로써 7일 이상 총회소집이 불가능한 때에는 재적 이사 과반수 또는 재적 회원 3분의 1 이상의 찬성으로 총회를 소집할 수 있다.</Paragraph>
                        <Paragraph>③ 제2항의 규정에 따른 총회는 출석 이사 중 최연장자의 사회아래 그 의장을 선출한다.</Paragraph>


                        <Heading>제5장 이사회</Heading>

                        <Subheading>제21조(이사회의 구성)</Subheading>
                        <Paragraph>이사회는 회장과 (부회장을 포함하여) 이사로 구성한다.</Paragraph>

                        <Subheading>제22조(이사회의 소집)</Subheading>
                        <Paragraph>
                            ① 이사회는 정기 이사회와 임시 이사회로 구분한다.
                            <br />
                            ② 정기 이사회는 연 2회 개최하고 임시 이사회는 감사 또는 이사의 3분의 1 이상의 요청이 있거나 회장이 필요하다고 인정하는 때에 소집한다.
                            <br />
                            ③ 회장은 이사회를 소집하고자 할 때에는 회의 개최 7일 전까지 이사 및 감사에게 회의의 목적과 안건, 개최 일시 및 장소를 통지하여야 한다. 다만, 긴급하다고 인정되는 정당한 사유가 있을 때에는 그러하지 아니한다.
                        </Paragraph>

                        <Subheading>제23조(이사회의 의결사항)</Subheading>
                        <Paragraph>
                            이사회는 다음 각 호의 사항을 심의․의결한다.
                        </Paragraph>
                        <List>
                            <ListItem>1. 업무집행에 관한 사항</ListItem>
                            <ListItem>2. 사업계획의 운영에 관한 사항</ListItem>
                            <ListItem>3. 예산․결산서의 작성에 관한 사항</ListItem>
                            <ListItem>4. 정관변경에 관한 사항</ListItem>
                            <ListItem>5. 재산관리에 관한 사항</ListItem>
                            <ListItem>6. 총회에 부칠 안건에 관한 사항</ListItem>
                            <ListItem>7. 총회에서 위임받은 사항</ListItem>
                            <ListItem>8. 정관의 규정에 따라 그 권한에 속하는 사항</ListItem>
                            <ListItem>9. 기타 본 법인의 운영상 중요하다고 회장이 부의하는 사항</ListItem>
                        </List>


                        <Subheading>제24조(정족수)</Subheading>
                        <Paragraph>이사회는 재적 이사 과반수의 출석으로 개회하고 출석 이사 과반수의 찬성으로 의결한다.</Paragraph>

                        <Subheading>제25조(서면결의 금지)</Subheading>
                        <Paragraph>
                            이사회의 의결은 서면결의에 의할 수 없다. 다만 이사회에 부의할 사항 중 경미한 사항 또는 긴급을 요하는 사항에 관하여는 이를 서면으로 의결하도록 할 수 있다. 이 경우에 회장은 그 결과를 차기 이사회에 보고하여야 한다.
                            <br />
                            ② 제1항의 서면결의 사항에 대하여 재적 이사 과반수가 이사회에 부칠 것을 요구하는 때에는 회장은 이에 따라야 한다.
                        </Paragraph>




                        <Heading>제6장 재산과 회계</Heading>

                        <Subheading>제26조(재산의 구분)</Subheading>
                        <Paragraph>
                            ① 법인의 재산은 다음과 같이 기본재산과 운영재산으로 구분한다.
                            <br />
                            ② 기본재산은 법인의 목적사업 수행에 관계되는 부동산 또는 동산으로서 법인 설립 시 그 설립자가 출연한 재산과 이사회에서 기본재산으로 정한 재산으로 하며 그 목록은 붙임과 같다.
                            <br />
                            ③ 운영재산은 기본재산 이외의 재산으로 한다.
                        </Paragraph>

                        <Subheading>제27조(재산의 관리)</Subheading>
                        <Paragraph>
                            ① 법인의 기본재산을 매도, 증여, 임대, 교환하거나 담보 제공 또는 용도 등을 변경하고자 할 때 또는 의무의 부담이나 권리를 포기하고자 할 때는 이사회와 총회의 의결을 거쳐야 한다.
                            <br />
                            ② 기본재산의 변경에 관하여는 정관변경에 관한 규정을 준용한다.
                        </Paragraph>

                        <Subheading>제28조(재원)</Subheading>
                        <Paragraph>
                            법인의 유지 및 운영에 필요한 경비의 재원은 다음 각 호와 같다.
                        </Paragraph>
                        <List>
                            <ListItem>1. 회비</ListItem>
                            <ListItem>2. 각종 기부금</ListItem>
                            <ListItem>3. 기본재산으로부터 생기는 과실금</ListItem>
                            <ListItem>4. 기타</ListItem>
                        </List>

                        <Subheading>제29조(회계연도)</Subheading>
                        <Paragraph>법인의 회계연도는 매년 1월 1일부터 12월 31일까지로 한다.</Paragraph>

                        <Subheading>제30조(예산편성 및 결산)</Subheading>
                        <Paragraph>
                            ① 법인은 회계연도 1개월 전에 사업계획 및 예산안을 이사회의 의결을 거쳐 총회의 승인을 얻는다.
                            <br />
                            ② 법인은 사업실적 및 결산 내용을 당해 회계연도 종료 후 2개월 이내에 이사회의 의결을 거쳐 총회의 승인을 얻는다.
                        </Paragraph>

                        <Subheading>제31조(회계감사)</Subheading>
                        <Paragraph>감사는 회계감사를 매년 실시하여야 한다.</Paragraph>

                        <Subheading>제32조(임원의 보수)</Subheading>
                        <Paragraph>임원에 대하여는 보수를 지급하지 아니한다. 다만, 업무수행에 필요한 실비는 예산의 범위 내에서 지급할 수 있다.</Paragraph>

                        <Subheading>제33조(차입금)</Subheading>
                        <Paragraph>법인이 예산 외의 의무부담이나 자금의 차입을 하고자 할 때에는 이사회의 의결을 거쳐야 한다.</Paragraph>


                        <Heading>제7장 사무부서</Heading>

                        <Subheading>제34조(사무국)</Subheading>
                        <Paragraph>
                            ① 본 법인의 사무를 처리하기 위하여 사무국을 둔다.
                            <br />
                            ② 사무국에 사무국장 1명과 필요한 직원을 둘 수 있다.
                            <br />
                            ③ 사무국장은 이사회의 의결을 거쳐 회장이 임명, 해임한다.
                            <br />
                            ④ 사무국의 조직 및 운영에 관한 사항은 이사회의 의결을 거쳐 별도로 정한다.
                        </Paragraph>

                        <Heading>제8장 보 칙</Heading>

                        <Subheading>제35조(법인해산)</Subheading>
                        <Paragraph>
                            ① 법인을 해산하고자 할 때에는 총회에서 재적회원 4분의 3 이상의 찬성으로 의결하여 주무관청에 신고하여야 한다.
                            <br />
                            ② 법인의 해산 시 잔여재산은 총회 회의를 거처 주무관청의 허가를 받아 국가, 지방자치단체 또는 다른 유사한 목적을 가지는 단체에 귀속한다.
                        </Paragraph>

                        <Subheading>제36조(정관변경)</Subheading>
                        <Paragraph>
                            이 정관을 변경하고자 할 때에는 재적이사 3분의 2 이상의 찬성과 총회에서 재적회원 3분의 2 이상의 찬성으로 의결하여 주무관청의 허가를 받아야 한다.
                        </Paragraph>

                        <Subheading>제37조(업무보고)</Subheading>
                        <Paragraph>
                            다음 연도의 사업계획서 및 예산서와 해당연도 사업실적서 및 수지결산서는 회계연도 종료 후 2개월 이내에 주무관청에 보고하여야 한다. 이 경우 재산목록과 업무현황 및 감사결과 보고서도 함께 제출하여야 한다.
                        </Paragraph>

                        <Subheading>제38조(준용규정)</Subheading>
                        <Paragraph>
                            이 정관에 규정되지 아니한 사항은 민법 중 사단법인에 관한 규정과 환경부 및 기상청 소관 비영리법인의 설립과 감독에 관한 규칙을 준용한다.
                        </Paragraph>


                        <Subheading>제39조(규칙제정)</Subheading>
                        <Paragraph>
                            이 정관이 정한 것 외에 본 법인의 운영에 관하여 필요한 사항은 이사회의 의결을 거쳐 규칙으로 정한다.
                        </Paragraph>

                        <Subheading>부 칙</Subheading>
                        <Paragraph>
                            제1조(시행일) 이 정관은 주무관청의 정관 변경 허가를 받은 날부터 시행한다.
                            <br />
                            제2조(기명날인) 이 정관은 2022년 11월 22일 총회를 통해 변경되었으며, 다음과 같이 의장 및 총회에 참석한 임원이 기명 날인했다.<br></br>
                        </Paragraph>
                        <h1>사단법인 생물다양성보전협회</h1><br></br>
                        <ParagraphRight>
                            <b>이사(회장)</b> 공 명 식 (인) <br /> <b>이사(부회장)</b> 장 영 일 (인)
                            <br />
                            <b>이사</b> 변 화 근 (인) &nbsp;  <b>이사</b> 강 희 찬 (인)
                            <br />
                            <b>이사</b> 강 철 수 (인) &nbsp; <b>감사</b> 변 종 환 (인)
                        </ParagraphRight>

                        <TableWrapper>
                            <Table>
                                <TableHeading>
                                    <TableRow>
                                        <TableHeadingCell>지부명칭</TableHeadingCell>
                                        <TableHeadingCell>지부 주소</TableHeadingCell>
                                        <TableHeadingCell2>지부장</TableHeadingCell2>
                                    </TableRow>
                                </TableHeading>
                                <tbody>
                                    <TableRow>
                                        <TableCell>광주,전남지부</TableCell>
                                        <TableCell>전남 담양군 담양읍 지침3길 7-29 연빌라 나동 201호</TableCell>
                                        <TableCell2>박상현</TableCell2>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>전북지부</TableCell>
                                        <TableCell>전북 장수군 계남면 동정길 25-41 </TableCell>
                                        <TableCell2>성승일</TableCell2>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>대구,경북지부</TableCell>
                                        <TableCell>경북 포항시 북구 우창로 20 신동아베르디 201동 101호</TableCell>
                                        <TableCell2>강정훈</TableCell2>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>제주지부</TableCell>
                                        <TableCell>제주특별자치도 제주시 신대로 18길 16, 1층 106호(연동,삼다맨션)</TableCell>
                                        <TableCell2>황영수</TableCell2>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>대전,충남지부</TableCell>
                                        <TableCell>충남 홍성군 광천읍 홍남로 744번길 19-17</TableCell>
                                        <TableCell2>권용규</TableCell2>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>강원지부</TableCell>
                                        <TableCell>강원도 화천군 사내면 사창리 310-4</TableCell>
                                        <TableCell2>정문호</TableCell2>
                                    </TableRow>
                                </tbody>
                            </Table>
                        </TableWrapper>
                        {/* <button onClick={onClose}>Close</button> */}
                        {/* <button onClick={isOpen}>Close</button> */}
                        <span onClick={(event) => { event.stopPropagation(); setIsOpen_M(false) }}>
                            닫기 </span>
                    </Wrapper>

                </ModalContent>
            </ModalWrapper>
        </>
    )
}

export default Modal_Mob;



// Styled Components
const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 80%;
  overflow-y: auto;
  background-color: white; 
  
  /* rgba(0, 0, 0, 0.5); */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;

  font-size: 0.5em;
  border-radius: 2vh
`;

const BtnLight = styled.button`
position: fixed;
font-size: 2.3em;
font-weight: bold;
top: 2vh;
right: 2vh;
background-color: #7a7a7a;
`

const ModalTop = styled.div`
position: fixed;
top: 0px;
width: 100%;
height: 5vh;
background-color: #7a7a7a;
`


const ModalContent = styled.div`
text-align: left;
  border-radius: 4px;
  width: 100%;
  height: 80vh;
  padding: 7vh 2vh 7vh 2vh;
  
  border-radius: 4px;
  display: flex;

  
`;

const ModalBottom = styled.div`
bottom:5%;
`;
const SlideWrap = styled.div`
height:95%
`

const PageOne = styled.div`
width: 100%;
height: 14vh;
`
const SlideButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  font-size: 20px;
  color: #999;
  cursor: pointer;
  padding: 0;
`;




const SubheadingMain = styled.h3`
 font-weight: bold;
  font-size: 1.3em;
  margin-bottom: 10px;
`;

const ParagraphRaght = styled.p`
  text-align: right;
  margin-bottom: 0px;
`
///


const Wrapper = styled.div`
  font-family: Arial, sans-serif;
  padding: 1vh;
  height: 95%;
`;

const Heading = styled.h2`
  font-size: 1.5em;
  font-weight: bold;
  padding: 3vh 0  4vh 0;
  
`;

const Subheading = styled.h3`
font-weight: bold;
text-align: left;
  font-size: 1em;
  
`;
const Paragraph = styled.p`
  margin-bottom: 10px;
  text-align: left;
`;

const List = styled.ul`

  margin-bottom: 10px;
`;

const ListItem = styled.li`

font-size: 0.8em;
  margin-bottom: 10px;
text-align: left;
  list-style-type: none;
`;






const TableWrapper = styled.div`
  overflow-x: auto;
  text-align: center;
   vertical-align: middle; 
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  text-align: center;
   vertical-align: middle; 
`;

const TableHeading = styled.thead`
  background-color: #f2f2f2;
  text-align: center;
   vertical-align: middle; 
`;

const TableRow = styled.tr`
text-align: center;
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const TableHeadingCell = styled.th`

  font-size: 0.5em;
  text-align: center;
   vertical-align: middle; 
  font-weight: bold;
  border: 1px solid #ccc;
`;
const TableHeadingCell2 = styled.th`
width: 13%;
  font-size: 0.5em;
  text-align: center;
   vertical-align: middle; 
  font-weight: bold;
  border: 1px solid #ccc;
`;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ccc;
   vertical-align: middle; 
`;
const TableCell2 = styled.td`
width: 13%;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #ccc;
   vertical-align: middle; 
`;



const ParagraphRight = styled.p`
font-size: 1.2em;
padding-right: 2%;
  text-align: right;
  margin-bottom: 1%;

  h1{
    text-align: right;
    line-height: 5vh;
  }
  b{
    font-weight: 600;
  }
`;



