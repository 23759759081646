import React from 'react'
import styled from 'styled-components';

const Modal_Slide_5 = () => {
  return (
    <>
      <Wrapper>
        <Heading>제5장 이사회</Heading>

        <Subheading>제21조(이사회의 구성)</Subheading>
        <Paragraph>이사회는 회장과 (부회장을 포함하여) 이사로 구성한다.</Paragraph>

        <Subheading>제22조(이사회의 소집)</Subheading>
        <Paragraph>
          ① 이사회는 정기 이사회와 임시 이사회로 구분한다.
          <br />
          ② 정기 이사회는 연 2회 개최하고 임시 이사회는 감사 또는 이사의 3분의 1 이상의 요청이 있거나 회장이 필요하다고 인정하는 때에 소집한다.
          <br />
          ③ 회장은 이사회를 소집하고자 할 때에는 회의 개최 7일 전까지 이사 및 감사에게 회의의 목적과 안건, 개최 일시 및 장소를 통지하여야 한다. 다만, 긴급하다고 인정되는 정당한 사유가 있을 때에는 그러하지 아니한다.
        </Paragraph>

        <Subheading>제23조(이사회의 의결사항)</Subheading>
        <Paragraph>
          이사회는 다음 각 호의 사항을 심의․의결한다.
        </Paragraph>
        <List>
          <ListItem>1. 업무집행에 관한 사항</ListItem>
          <ListItem>2. 사업계획의 운영에 관한 사항</ListItem>
          <ListItem>3. 예산․결산서의 작성에 관한 사항</ListItem>
          <ListItem>4. 정관변경에 관한 사항</ListItem>
          <ListItem>5. 재산관리에 관한 사항</ListItem>
          <ListItem>6. 총회에 부칠 안건에 관한 사항</ListItem>
          <ListItem>7. 총회에서 위임받은 사항</ListItem>
          <ListItem>8. 정관의 규정에 따라 그 권한에 속하는 사항</ListItem>
          <ListItem>9. 기타 본 법인의 운영상 중요하다고 회장이 부의하는 사항</ListItem>
        </List>


      </Wrapper>

    </>
  )
}

export default Modal_Slide_5


const Wrapper = styled.div`
  font-family: Arial, sans-serif;
  padding: 1vh;
  height: 95%;
`;

const Heading = styled.h2`
  font-size: 1.5em;
  font-weight: bold;
  padding: 3vh 0  4vh 0;
  
`;
const Subheading = styled.h3`
font-weight: bold;
text-align: left;
  font-size: 1em;
  
`;
const Paragraph = styled.p`
text-align: left;
  margin-bottom: 1%;
`;


const List = styled.ul`
  margin-bottom: 0.3em;
`;

const ListItem = styled.li`

font-size: 0.8em;
  text-align: left;
  list-style-type: none;
`;