import React from "react";
import styled from "styled-components";

import backImage from "../images/useImage/4_Section.png";

import Slider from "react-slick";

import slickIMG from "../images/slick/꿀벌.jpg";
import slickIMG_2 from "../images/slick/야광.jpg";
import slickIMG_3 from "../images/slick/새싹.jpg";
import slickIMG_4 from "../images/slick/트인강.jpg";
import slickIMG_5 from "../images/slick/상어비닐.jpg";
import slickIMG_6 from "../images/slick/새.jpg";
import slickIMG_7 from "../images/slick/수달.jpg";
import slickIMG_9 from "../images/slick/백곰.jpg";
import slickIMG_10 from "../images/slick/호랑이.jpg";

import { DESKTOP, MOBILE, TABLET } from "styles/MediaQuery";
import { BsTypeH3 } from "react-icons/bs";

const LineSection_4 = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Wrap id="section3">
        <Slider {...settings}>
          {/*        
          <Carousel_Warp>
            <Tag>
              <OrangeH3>#지키기위해</OrangeH3>
            </Tag>
            <MentBold>
              <h1> 생물다양성</h1>
            </MentBold>
            <InfoData>
              <h4>생물 다양성 보존 및 이용에 관한업무</h4>
            </InfoData>
            <img src={slickIMG} alt="" />

            <CommentMini>
              우리는 자연을 보호하고 복원하며,<br></br>
              우리의 미래를 책임질 <br></br>
              생물 다양성을 보전하는데 앞장섭니다.
            </CommentMini>
          </Carousel_Warp>

          <Carousel_Warp>
            <Tag>
              <GreenH3>#자연을건강하게</GreenH3>
            </Tag>
            <MentBold>
              <h1> 생태계교란생물</h1>
            </MentBold>
            <InfoData>
              <h4>생태계교란생물 퇴치 및 관리업무</h4>
            </InfoData>
            <img src={slickIMG_2} alt="" />

            <CommentMini>
              생태계의 균형을 유지하기 위해<br></br>
              생태계를 교란시키는 유해 생물들을<br></br>
              퇴치하고 관리함으로써<br></br>
              생태계 및 생물 다양성을 보호를<br></br>
              목표로 합니다.<br></br>
            </CommentMini>
          </Carousel_Warp>

          <Carousel_Warp>
            <Tag>
              <GreenLowH3>#자연에게 배운다</GreenLowH3>
            </Tag>
            <MentBold>
              <h1> 교육 및 홍보</h1>
            </MentBold>
            <InfoData>
              <h4>생물 다양성 보전 및 이용에 관한 교육과 홍보 업무</h4>
            </InfoData>
            <img src={slickIMG_3} alt="" />

            <CommentMini>
              대중들에게 생물 다양성 및 <br></br>
              생물자원보전의 소중함과 중요성을 인식하고 <br></br>
              지속 가능한 지구를 만들어나갈 <br></br>
              미래세대의 교육과 홍보 활동에 앞장섭니다. <br></br>
            </CommentMini>
          </Carousel_Warp>

          <Carousel_Warp>
            <Tag>
              <SkyH3>#지속적인 관심</SkyH3>
            </Tag>
            <MentBold>
              <h1>모니터링 업무</h1>
            </MentBold>
            <InfoData>
              <h4>자연생태계 모니터링 업무</h4>
            </InfoData>
            <img src={slickIMG_6} alt="" />

            <CommentMini>
              자연환경의 변화를 추적하고 분석하여<br></br>
              생태계의 건강 상태 파악을 통해 문제가 발생한 경우<br></br>
              환경 조건의 변화와 그에 따른 생태계 변화를 파악하여<br></br>
              적절한 대처 방안을 마련합니다.<br></br>
            </CommentMini>
          </Carousel_Warp>

          <Carousel_Warp>
            <Tag>
              <BlueH3>#돌아온 호랑이</BlueH3>
            </Tag>
            <MentBold>
              <h1>멸종위기종</h1>
            </MentBold>
            <InfoData>
              <h4>멸종위기종 복원사업</h4>
            </InfoData>
            <img src={slickIMG_10} alt="" />

            <CommentMini>
              우리나라의 멸종 위기종은<br></br>
              동물과 식물을 포함하여 무려 282종에 달합니다.<br></br>
              멸종 위기종의 복원은 종의 보존뿐만 아니라<br></br>
              생태계 전체적인 안정성과 생물 다양성을 증진시키는데<br></br>큰
              역할을 합니다.
            </CommentMini>
          </Carousel_Warp>

          <Carousel_Warp>
            <Tag>
              <OrangeH3>#항상 여기 있을게</OrangeH3>
            </Tag>
            <MentBold>
              <h1>야생생물 지킴이</h1>
            </MentBold>
            <InfoData>
              <h4>서식지 외 보전기관 사업</h4>
            </InfoData>
            <img src={slickIMG_7} alt="" />

            <CommentMini>
              서식지 내에서 보전이 어려운 야생생물을<br></br>
              서식지 외에서 체계적인 보전, 증식을 통해<br></br>
              생물종의 서식 가능성을 높이고<br></br>
              생물 다양성을 유지하는데 기여합니다.<br></br>
            </CommentMini>
          </Carousel_Warp>

          <Carousel_Warp>
            <Tag>
              <BrownH3>#모두 와 함께</BrownH3>
            </Tag>
            <MentBold>
              <h1>혼자가 아닌 우리</h1>
            </MentBold>
            <InfoData>
              <h4>정부 및 지방자치단체 또는 공공기관으로부터 위탁받은 사업</h4>
            </InfoData>
            <img src={slickIMG_4} alt="" />

            <CommentMini>
              저희 “생물다양성보전협회”는<br></br>
              2010년부터 지금까지 정부 및 지방자치단체 또는 공공기관으로부터
              <br></br>
              교란어종 퇴치 및 관리, 교란종 서식밀도 및 실태조사 등<br></br>
              건강한 수생태계 보전을 위해 열심히 달려왔습니다.<br></br>
            </CommentMini>
          </Carousel_Warp>

          <Carousel_Warp>
            <Tag>
              <GreenH3>#환경은글로벌문제</GreenH3>
            </Tag>
            <MentBold>
              <h1>국제적인 협력</h1>
            </MentBold>
            <InfoData>
              <h4>생물 다양성 보전에 관한 국제적인 협력업무</h4>
            </InfoData>
            <img src={slickIMG_5} alt="" />

            <CommentMini>
              앞으로 저희 “생물다양성보전협회”는<br></br>
              국내 수생태계 교란어종 퇴치사업에 국한되지 않고<br></br>
              지구의 자연환경을 보전하며 인류와 자연 생태계 모두에게<br></br>
              이로움을 줄 수 있는 환경단체로 탈바꿈하도록 노력하겠습니다.
              <br></br>
            </CommentMini>
          </Carousel_Warp>

          <Carousel_Warp>
            <Tag>
              <WhiteH3>#포기하지않을게</WhiteH3>
            </Tag>
            <MentBold>
              <h1>우리의 목표를 위해</h1>
            </MentBold>
            <InfoData>
              <h4>
                기타 본 법인의 발전과 목적 달성에 필요하다고 인정되는 사업
              </h4>
            </InfoData>
            <img src={slickIMG_9} alt="" />

            <CommentMini>
              생물 다양성 보전을 위해서는<br></br>
              이외에도 다양한 방법들이 존재합니다.<br></br>
              지구의 모든 생물을 위해서라면<br></br>
              아무리 작은 일이라도<br></br>
              저희 단체가 항상 앞장서겠습니다.
            </CommentMini>
          </Carousel_Warp> */}



          <Carousel_Warp>
            <Tag>
              <OrangeH3>#협회 활동</OrangeH3>
            </Tag>
            <MentBold>
              {/* <h1>우리의 목표를 위해</h1> */}
            </MentBold>
            {/* <InfoData>
              <h4>
                기타 본 법인의 발전과 목적 달성에 필요하다고 인정되는 사업
              </h4>
            </InfoData> */}
            <img src={slickIMG_2} alt="" />

            <CommentMini>
              생물다양성 보전 및 이용에 관한 업무<br></br>
              생태계교란생물 퇴치 및 관리<br></br>
              생물다양성 보전 및 이용에 관한 교육과 홍보<br></br>
              정부 및 지방자치단체 또는 공공기관으로 부터 위탁받은 사업<br></br>
              자연생태계 모니터링<br></br>
            </CommentMini>
          </Carousel_Warp>

          <Carousel_Warp>
            <Tag>
              <WhiteH3>#유튜브 채널</WhiteH3>
            </Tag>
            <MentBold>
              <h1> "생다수" - 생물다양성수호대</h1>
            </MentBold>
            {/* <InfoData>
              <h4>
                기타 본 법인의 발전과 목적 달성에 필요하다고 인정되는 사업
              </h4>
            </InfoData> */}
            <img src={slickIMG_4} alt="" />

            <CommentMini>
              생생물다양성보전협회의 공식 채널로 협회의 다양한 활동,<br></br>
              청소년들과 현장전문가분들을 대상으로 하는<br></br>
              지속적인 교육 활동을 소개합니다.<br></br>
            </CommentMini>
          </Carousel_Warp>


          <Carousel_Warp>
            <Tag>
              <BlueH3>#청소년과의 방향성 </BlueH3>
            </Tag>
            <MentBold>
              <h1>청소년 지원 </h1>
            </MentBold>
            {/* <InfoData>
              <h4>
                기타 본 법인의 발전과 목적 달성에 필요하다고 인정되는 사업
              </h4>
            </InfoData> */}
            <img src={slickIMG_7} alt="" />

            <CommentMini>
              - 장학금 지원<br></br>
              기초생활수급 가정, 한부모 가정, 차상위계층 등의 우선으로 선정하여 지원<br></br>

            </CommentMini>
          </Carousel_Warp>

          <Carousel_Warp>
            <Tag>
              <SkyH3>#교육프로그램</SkyH3>
            </Tag>
            {/* <MentBold>
              <h1>청소년 지원  </h1>
            </MentBold> */}
            {/* <InfoData>
              <h4>
                기타 본 법인의 발전과 목적 달성에 필요하다고 인정되는 사업
              </h4>
            </InfoData> */}
            <img src={slickIMG_6} alt="" />

            <CommentMini>
              - 현장체험 및 답사 <br></br> 생물다양성 보전 및 생태계 전반에 대한 시청각교육 프로그램과 현장 체험학습<br></br>
              - 청소년 교육 활동 <br></br> 지속으로 운영되는 체험학습을 통해 환경교육 활동 및 환경행사 참여<br></br>
              - 현장형 생활 안전교육 : 일상생활에 필요한 기초 안전교육<br></br>
              (심폐소생술, 재난, 화재, 생존 등 재난관련 위험효인을 파악하고 체험하는 학습)<br></br>

            </CommentMini>
          </Carousel_Warp>


          <Carousel_Warp>
            <Tag>
              <GreenH3>#운영</GreenH3>
            </Tag>
            {/* <MentBold>
              <h1>청소년 지원  </h1>
            </MentBold> */}
            {/* <InfoData>
              <h4>
                기타 본 법인의 발전과 목적 달성에 필요하다고 인정되는 사업
              </h4>
            </InfoData> */}
            <img src={slickIMG_10} alt="" />

            <CommentMini>
              청소년을 제외한 성인 회원의 자발적 후원금과 기부금으로 행사 운영 및 장학금 지원.<br></br>
              지자체, 환경청, 교육 기관 등 관련 기관과 협조하여 행사 운영<br></br>

            </CommentMini>
          </Carousel_Warp>


          <Carousel_Warp>
            <Tag>
              <GreenLowH3>#효과</GreenLowH3>
            </Tag>
            {/* <MentBold>
              <h1>청소년 지원  </h1>
            </MentBold> */}
            {/* <InfoData>
              <h4>
                기타 본 법인의 발전과 목적 달성에 필요하다고 인정되는 사업
              </h4>
            </InfoData> */}
            <img src={slickIMG_9} alt="" />

            <CommentMini>
              장학금 지원으로 청소년들의 환경교육 활동에 도움<br></br>
              현장체험 프로그램을 통해 미래세대의 생태환경 전반에 대한 중요성을 인지,함양<br></br>
              자발적 자원봉사로 원활한 행사운영과 봉사활동 정착 (일반, 대학생 서포터즈)<br></br>
              현장형 기초 안전교육 이수로 재난 및 위험 상황 대처능력 배양<br></br>
              관련 기관과 협조하여, 지속적으로 행사를 추진하여 지역 간 왕래 증가 및 자연스러운 홍보 효과<br></br>

            </CommentMini>
          </Carousel_Warp>







        </Slider>
      </Wrap>
    </>
  );
};

export default LineSection_4;

const Wrap = styled.div`
  height: 100vh;
  width: 85%;
  margin: 0 auto;
  /* background: linear-gradient(
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 1) 100%
    ),
    url(${backImage});
  background-size: cover; */
  @media ${MOBILE} {
    width: 80%;
    margin: 0 auto;
  }

  @media ${TABLET} {
    background-image: none;
    width: 60%;
    margin: 0 auto;
  }
  @media ${DESKTOP} {
    background-image: none;
    width: 60%;
    margin: 0 auto;
  }
`;

const Carousel_Warp = styled.div`
  padding-top: 10vh;
  width: 100%;

  @media ${MOBILE} {
    padding-top: 15vh;
    opacity: 1.5;
    width: 80%;
    img {
      max-width: 70%;
      margin: 0 auto;
      //이미지 위치
    }
  }
  @media ${TABLET} {
    padding-top: 20vh;
    opacity: 1.5;
    width: 70%;
    img {
      max-width: 80%;
      margin: 0 auto;
      //이미지 위치
    }
  }

  @media ${DESKTOP} {
    padding-top: 1vh;
    opacity: 1.5;
    width: 60%;

    img {
      max-width: 50%;
      margin: 0 auto;
      //이미지 위치
    }
  }
`;

const Tag = styled.div`
  padding: 1.2em;

  h3 {
    font-size: 1.8em;
  }

  @media ${DESKTOP} {
    font-size: 2em;
  }
`;
const OrangeH3 = styled.h3`
  color: orange;
  font-weight: bold;
`;

const GreenH3 = styled.h3`
  color: green;
  font-weight: bold;
`;
const GreenLowH3 = styled.h3`
  color: #bdd144;
  font-weight: bold;
`;

const BrownH3 = styled.h3`
  color: #d2ceb5;
  font-weight: bold;
`;

const SkyH3 = styled.h3`
  color: #79b2df;
  font-weight: bold;
`;

const BlueH3 = styled.h3`
  color: #00677e;
  font-weight: bold;
`;

const WhiteH3 = styled.h3`
  color: #e6edf2;
  font-weight: bold;
`;

const MentBold = styled.div`
  padding: 0.8em;
  h1 {
    font-size: 1.2em;
    color: white;
  }
  @media ${DESKTOP} {
    padding: 1em;
    h1 {
      font-size: 1.8em;
      color: white;
    }
  }
`;

const InfoData = styled.div`
  padding: 1.5em 0;
  h4 {
    font-size: 1.1em;
    color: white;
  }

  @media ${MOBILE} {
    padding: 1em;
    h4 {
      font-size: 1.5em;
      color: white;
    }
  }

  @media ${DESKTOP} {
    padding: 1em;
    h4 {
      font-size: 1.5em;
      color: white;
    }
  }
`;

const CommentMini = styled.div`
  padding-top: 1.5vh;
  color: white;
  font-size: 1.2em;
  line-height: 5vh;

  @media ${MOBILE} {
    padding-top: 1.8vh;
    width: 65%;
    margin: 0 auto;
    font-size: 1.2em;
  }

  @media ${DESKTOP} {
    padding-top: 1.8vh;
    width: 65%;
    margin: 0 auto;

    font-size: 1.2em;
  }
`;
