import React from 'react'
import styled from 'styled-components'

export const Modal_Slide_1 = () => {
  return (
    <>
      <Wrapper>
        <Heading>사단법인 생물다양성 보전협회 정관</Heading>
        <ParagraphRaght>제정 2014. 09. 18.</ParagraphRaght>
        <ParagraphRaght>개정 2022. 11. 22.</ParagraphRaght>

        <SubheadingMain>제1장 총 칙</SubheadingMain>
        <Subheading>제1조(명칭)</Subheading>
        <Paragraph>
          본 법인의 명칭은 “사단법인 생물다양성보전협회”(이하 “법인”이라 한다)이라 한다.
        </Paragraph>

        <Subheading>제2조(목적)</Subheading>
        <Paragraph>
          이 법인은 생태계교란종 퇴치 및 생물다양성 보전 관리를 수행함으로써 정부의 생태계 보호 및 생물다양성 보전 관리 정책에 적극 기여함을 목적으로 한다.
        </Paragraph>

        <Subheading>제3조(사무소의 소재지)</Subheading>
        <Paragraph>
          ① 법인의 주사무소는 충청북도 청주시에 두며, 분사무소(지부)는 광역자치단체별로 추가하여 둘 수 있다.
          <br />
          ② 제1항에 따른 광역시.도 지부 및 시.군.구 지회의 설치.운영 등에 관한 구체적인 사항은 이사회의 의결로 정한다.
        </Paragraph>

        <Subheading>제4조(사업)</Subheading>
        <Paragraph>
          법인은 제2조의 목적을 달성하기 위하여 다음 각 호의 사업을 수행한다.
          <br />
          1. 생물다양성 보전 및 이용에 관한 업무
          <br />
          2. 생태계교란생물 퇴치 및 관리업무
          <br />
          3. 생물다양성 보전 및 이용에 관한 교육과 홍보 업무
          <br />
          4. 정부 및 지방자치단체 또는 공공기관으로부터 위탁받은 사업
          <br />
          5. 생물다양성 보전에 관한 국제적인 협력업무
          <br />
          6. 자연생태계 모니터링 업무
          <br />
          7. 멸종위기종 복원사업
          <br />
          8. 서식지외 보전기관 사업
          <br />
          9. 기타 본 법인의 발전과 목적 달성에 필요하다고 인정되는 사업
        </Paragraph>
      </Wrapper>
    </>
  )
}
export default Modal_Slide_1;
const Wrapper = styled.div`
  font-family: Arial, sans-serif;
  padding: 1vh;
  height: 95%;
`;

const Heading = styled.h2`
  font-size: 1.5em;
  font-weight: bold;
  padding: 3vh 0  2vh 0;
  
`;

const Subheading = styled.h3`
font-weight: bold;
text-align: left;
  font-size: 1em;
  
`;

const SubheadingMain = styled.h3`
 font-weight: bold;
  font-size: 1.3em;
  margin-bottom: 10px;
`;

const Paragraph = styled.p`
text-align: left;
font-size: 0.8em;
  margin-bottom: 10px;
`;
const ParagraphRaght = styled.p`
  text-align: right;
  margin-bottom: 0px;
`

const ModalContent = styled.div`
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 5px;
`;
