import React from "react";
import Slider from "react-slick";
import "../components/CarouselData/slick.css";
import "slick-carousel/slick/slick-theme.css";

import styled from "styled-components";
import { DESKTOP } from "styles/MediaQuery";

import slickIMG from "../images/useImage/slick_꿀벌.jpg";
import slickIMG_2 from "../images/useImage/slick_야광.jpg";
import slickIMG_3 from "../images/useImage/slick_새싹.jpg";
import slickIMG_4 from "../images/useImage/slick_트인강.jpg";
import slickIMG_5 from "../images/useImage/slick_상어비닐.jpg";
import slickIMG_6 from "../images/useImage/slick_새.jpg";
import slickIMG_7 from "../images/useImage/slick_수달.jpg";
import slickIMG_8 from "../images/useImage/slick_개울.jpg";
import slickIMG_9 from "../images/useImage/slick_백곰.jpg";

const Section_Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <SliderWrap>
        <Slider {...settings}>
          <CarouselWrap>
            <CarouselLeft>
              <h2> #지키기위해 </h2>
              <h1> 생물다양성</h1>
              <h3>생물다양성 보전 및 이용에 관한 업무</h3>
              <p>
                갑작스러운 온난화로 지금 이순간에도, 꿀벌은 사라지고있습니다.
                꿀벌이 사라지면, 이라는 다큐가 있을정도로 꿀벌은 모두에게 소중한
                존재입니다. 사람에게,동물에게 자연에게 말이죠!
              </p>
            </CarouselLeft>
            <CarouselRight>
              <img src={slickIMG} alt="IMG" />
            </CarouselRight>
          </CarouselWrap>

          <CarouselWrap>
            <CarouselLeft>
              <h2> #자연을건강하게 </h2>
              <h1> 생태계교란생물</h1>
              <h3>생태계교란생물 퇴치 및 관리업무</h3>
              <p>
                생태계 교란생물 퇴치 및 관리는 생태계의 균형을 유지하기 위해
                행해지는 중요한 업무다. 야생동물, 식물, 해양 생물 등 다양한
                생물들이 서로 영향을 주고받는데, 이 중에서 생태계를 교란시키는
                유해 생물들을 퇴치하고 관리함으로써 생태계를 보호하고 지속
                가능한 생태계를 유지하는 것이 목표다. 생태계교란생물 퇴치와
                관리는 도전적이고 다양한 방법과 기술이 사용되며, 자연을 사랑하고
                동물과 식물의 보호를 위해 노력하는 활동이 흥미로운 분야다!
              </p>
            </CarouselLeft>
            <CarouselRight>
              <img src={slickIMG_2} alt="IMG" />
            </CarouselRight>
          </CarouselWrap>

          <CarouselWrap>
            <CarouselLeft>
              <h2> #자연에게배웁니다 </h2>
              <h1> 교육과 홍보</h1>
              <h3>생물다양성 보전 및 이용에 관한 교육과 홍보 업무</h3>
              <p>
                여기에 맞는 멘트를 짜주셔야 할거 같아요 제 머리로는 이쪽에 대한
                정보나 지식이 아쉬워서 내용을 채우기가 힘들거같습니다.. 시간도
                걸릴것 같고 해당 내용은 남겨주시면 변경 가능합니다. 상단 샾
                또한. 컬러 및 문구
              </p>
            </CarouselLeft>
            <CarouselRight>
              <img src={slickIMG_3} alt="IMG" />
            </CarouselRight>
          </CarouselWrap>

          <CarouselWrap>
            <CarouselLeft>
              <h2> #정부와함께 </h2>
              <h1>혼자가 아닌 우리 </h1>
              <h3>정부 및 지방자치단체 또는 공공기관으로부터 위탁받은 사업</h3>
              <p>
                산림에서 나무를 베거나 수공업을 수행하거나 농작물을 재배하는
                것과 같은 인간의 활동들은 생태계를 변화시킵니다. 그러나 이러한
                활동들이 지속가능한 방식으로 수행되도록 노력하면, 우리는 자연의
                미래를 보호하고 유지할 수 있습니다.
              </p>
            </CarouselLeft>
            <CarouselRight>
              <img src={slickIMG_4} alt="IMG" />
            </CarouselRight>
          </CarouselWrap>

          <CarouselWrap>
            <CarouselLeft>
              <h2> #환경은글로벌문제 </h2>
              <h1>국제적인협력</h1>
              <h3>생물다양성 보전에 관한 국제적인 협력업무</h3>
              <p>
                여기에 맞는 멘트를 짜주셔야 할거 같아요 제 머리로는 이쪽에 대한
                정보나 지식이 아쉬워서 내용을 채우기가 힘들거같습니다.. 시간도
                걸릴것 같고 해당 내용은 남겨주시면 변경 가능합니다. 상단 샾
                또한. 컬러 및 문구
              </p>
            </CarouselLeft>
            <CarouselRight>
              <img src={slickIMG_5} alt="IMG" />
            </CarouselRight>
          </CarouselWrap>

          <CarouselWrap>
            <CarouselLeft>
              <h2> #멸종위기로부터</h2>
              <h1>돌아온호랑이</h1>
              <h3>멸종위기종 복원사업</h3>
              <p>
                여기에 맞는 멘트를 짜주셔야 할거 같아요 제 머리로는 이쪽에 대한
                정보나 지식이 아쉬워서 내용을 채우기가 힘들거같습니다.. 시간도
                걸릴것 같고 해당 내용은 남겨주시면 변경 가능합니다. 상단 샾
                또한. 컬러 및 문구
              </p>
            </CarouselLeft>
            <CarouselRight>
              <img src={slickIMG_6} alt="IMG" />
            </CarouselRight>
          </CarouselWrap>

          <CarouselWrap>
            <CarouselLeft>
              <h2>#포기하지않을께</h2>
              <h1>이룰때까지, 목표</h1>
              <h3>
                기타 본 법인의 발전과 목적 달성에 필요하다고 인정되는 사업
              </h3>
              <p>
                여기에 맞는 멘트를 짜주셔야 할거 같아요 제 머리로는 이쪽에 대한
                정보나 지식이 아쉬워서 내용을 채우기가 힘들거같습니다.. 시간도
                걸릴것 같고 해당 내용은 남겨주시면 변경 가능합니다. 상단 샾
                또한. 컬러 및 문구
              </p>
            </CarouselLeft>
            <CarouselRight>
              <img src={slickIMG_9} alt="IMG" />
            </CarouselRight>
          </CarouselWrap>

          <CarouselWrap>
            <CarouselLeft>
              <h2> #항상여기있을께</h2>
              <h1>지켜줄께,서식지</h1>
              <h3>서식지외 보전기관 사업</h3>
              <p>
                여기에 맞는 멘트를 짜주셔야 할거 같아요 제 머리로는 이쪽에 대한
                정보나 지식이 아쉬워서 내용을 채우기가 힘들거같습니다.. 시간도
                걸릴것 같고 해당 내용은 남겨주시면 변경 가능합니다. 상단 샾
                또한. 컬러 및 문구
              </p>
            </CarouselLeft>
            <CarouselRight>
              <img src={slickIMG_7} alt="IMG" />
            </CarouselRight>
          </CarouselWrap>

          <CarouselWrap>
            <CarouselLeft>
              <h2>#포기하지않을께</h2>
              <h1>이룰때까지, 목표</h1>
              <h3>
                기타 본 법인의 발전과 목적 달성에 필요하다고 인정되는 사업
              </h3>
              <p>
                여기에 맞는 멘트를 짜주셔야 할거 같아요 제 머리로는 이쪽에 대한
                정보나 지식이 아쉬워서 내용을 채우기가 힘들거같습니다.. 시간도
                걸릴것 같고 해당 내용은 남겨주시면 변경 가능합니다. 상단 샾
                또한. 컬러 및 문구
              </p>
            </CarouselLeft>
            <CarouselRight>
              <img src={slickIMG_8} alt="IMG" />
            </CarouselRight>
          </CarouselWrap>
        </Slider>
      </SliderWrap>
    </>
  );
};

export default Section_Carousel;

const SliderWrap = styled.div`
  width: 100%;
  margin-bottom: 100px;
`;

const CarouselWrap = styled.div`
  width: 100%;
  padding: 70px 30px 20px 30px;

  @media ${DESKTOP} {
    width: 80%;
  }
`;

const CarouselLeft = styled.div`
  width: 100%;
  margin-bottom: 40px;

  h2 {
    color: yellowgreen;
    font-size: 1.4em;
  }
  h1 {
    font-size: 1.8em;
  }

  h3 {
    font-size: 1.2;
    margin-bottom: 15%;
  }

  p {
    font-size: 20px;
    font-weight: 300;
    line-height: 1.8;
    letter-spacing: -1px;
    color: #000;
  }
`;

const CarouselRight = styled.div`
  width: 100%;

  img {
    height: 360px;
  }
  @media ${DESKTOP} {
  }
`;
