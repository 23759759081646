import React from "react";
import styled from "styled-components";
import backImage from "../images/useImage/5_Section.png";
import {
  DESKTOP,
  LAPTOP,
  MINMOBILE,
  MOBILE,
  PULLWIDE,
  TABLET,
} from "styles/MediaQuery";
import LeftImg from "../images/useImage/누끼텐트.png";
const LineSection_5 = () => {
  return (
    <>
      <Wrap id="section4">
        <ContentAll>
          <LeftMobile>
            <img src={LeftImg} alt="" />
          </LeftMobile>

          <RightAll>
            <Main>OUR STORY</Main>

            <Content>
              첫째, 자연과 그 자연의 일부로 살아가는 인류와의 공존입니다.
              <br></br>
              둘째, 자연과 인류의 절충된 발전과 관리입니다.<br></br>
              셋째, 보존과 복원을 통한 생물다양성의 증대입니다.<br></br>
              넷째, 생물다양성을 기반으로 한 새로운 환경정책입니다.<br></br>
              다섯째, 우리 모두에게 이로운 가치와 명분입니다.
            </Content>

            <Bottom>
              <Bold>지구</Bold>라는 삶의 터전을 공유하는 인류와 자연의 모든
              생물에게 전합니다. 서로를 존중하고 아끼고 사랑하는 서로를 위해
              지속적인 발전을 할수 있도록 포기하지 않고 노력하겠습니다.
            </Bottom>
          </RightAll>
        </ContentAll>
        <PcBottom>
          <Bold>지구</Bold>라는 삶의 터전을 공유하는 인류와 자연의 모든 생물에게
          전합니다.<br></br>
          서로를 존중하고 아끼고 사랑하는 서로를 위해 지속적인 발전을 할수
          있도록<br></br>
          포기하지 않고 노력하겠습니다.
        </PcBottom>
      </Wrap>
    </>
  );
};

export default LineSection_5;

const Wrap = styled.div`
  height: 100vh;
`;

const ContentAll = styled.div`
  width: 95%;
  padding-top: 12vh;
  display: block;
  margin: 0 auto;
  @media ${MINMOBILE} {
    display: block;
    padding-top: 10vh;
    width: 80%;
  }
  @media ${MOBILE} {
    display: block;
    padding-top: 7vh;
    width: 75%;
  }

  @media ${TABLET} {
    display: block;
    padding-top: 5vh;
    width: 75%;
  }
  @media ${LAPTOP} {
    display: block;
    padding-top: 5vh;
    width: 75%;
  }

  @media ${PULLWIDE} {
    display: flex;
    padding-top: 2vh;
    width: 85%;
    margin: 0 auto;
  }
`;

const LeftMobile = styled.div`
  padding: 1em;

  @media ${MOBILE} {
    padding-top: 1vh;
    /* box-shadow: 0 0 5px 0 rgba(0.5, 0.18, 0.17); */
    /* 그림자 효과를 추가합니다. */
    display: block;
    ::after {
      content: ""; /* 가상 요소를 만듭니다. */
      position: absolute; /* 가상 요소를 부모 div 태그를 기준으로 배치합니다. */
      z-index: -1; /* 가상 요소를 div 태그 뒤쪽으로 배치합니다. */
      top: 10px; /* 가상 요소의 위치를 지정합니다. */
      left: 10px; /* 가상 요소의 위치를 지정합니다. */
    }
  }

  @media ${TABLET} {
    padding-top: 10vh;
    /* box-shadow: 0 0 5px 0 rgba(0.5, 0.18, 0.17);  */
    /* 그림자 효과를 추가합니다. */
    display: block;
    ::after {
      content: ""; /* 가상 요소를 만듭니다. */
      position: absolute; /* 가상 요소를 부모 div 태그를 기준으로 배치합니다. */
      z-index: -1; /* 가상 요소를 div 태그 뒤쪽으로 배치합니다. */
      top: 10px; /* 가상 요소의 위치를 지정합니다. */
      left: 10px; /* 가상 요소의 위치를 지정합니다. */
    }
  }

  @media ${DESKTOP} {
    padding: 2em;
    width: 80%;
    display: block;

    /* box-shadow: 0 0 5px 0 rgba(0.2, 0.18, 0.17);  */
    /* 그림자 효과를 추가합니다. */
    ::after {
      content: ""; /* 가상 요소를 만듭니다. */
      position: absolute; /* 가상 요소를 부모 div 태그를 기준으로 배치합니다. */
      z-index: -1; /* 가상 요소를 div 태그 뒤쪽으로 배치합니다. */
      top: 10px; /* 가상 요소의 위치를 지정합니다. */
      left: 10px; /* 가상 요소의 위치를 지정합니다. */
    }
  }
  @media ${PULLWIDE} {
    padding: 0 2em 2em 2em;
    width: 50%;
    display: block;
    margin: 0 auto;
    //box-shadow: 0 0 5px 0 rgba(0.2, 0.18, 0.17); /* 그림자 효과를 추가합니다. */
    ::after {
      content: ""; /* 가상 요소를 만듭니다. */
      position: absolute; /* 가상 요소를 부모 div 태그를 기준으로 배치합니다. */
      z-index: -1; /* 가상 요소를 div 태그 뒤쪽으로 배치합니다. */
      top: 10px; /* 가상 요소의 위치를 지정합니다. */
      left: 10px; /* 가상 요소의 위치를 지정합니다. */
    }
  }
`;

const RightAll = styled.div`
  padding: 3em 4em;
  width: 100%;
  font-size: 1.2em;

  @media ${PULLWIDE} {
    padding: 1em 3em 1em 3em;
    width: 48%;
  }
`;

const Main = styled.div`
  display: none;
  width: 100%;
  text-align: left;
  @media ${PULLWIDE} {
    display: block;
    font-size: 3em;
    color: white;
    font-weight: bold;
  }
`;

const Content = styled.div`
  display: none;
  width: 100%;

  @media ${TABLET} {
    display: none;
    text-align: left;
    font-size: 0.8em;
    line-height: 3.5em;
    color: white;
  }

  @media ${LAPTOP} {
    display: none;
    text-align: left;
    font-size: 0.7em;
    line-height: 2.5em;
    color: white;
  }

  @media ${PULLWIDE} {
    display: block;
    text-align: left;
    font-size: 0.95em;
    line-height: 3.5em;
    color: white;
  }
`;
const Bottom = styled.div`
  color: white;
  bottom: 0px;
  display: block;

  @media ${PULLWIDE} {
    display: none;
  }
`;
const PcBottom = styled.div`
  display: none;

  @media ${LAPTOP} {
    display: none;
  }

  @media ${DESKTOP} {
  }

  @media ${PULLWIDE} {
    padding-top: 7vh;
    display: block;
    color: white;
    bottom: 0px;
    font-size: 1.8em;
  }
`;

const Bold = styled.b`
  font-size: 1.2em;
  font-weight: bold;
  color: orange;
`;
