const size = {
  minmobile: "576px",
  mobile: "768px",
  tablet: "992px",
  laptop: "1200px",
  desktop: "1300px",
  pullwide: "1500px",
};
export const MINMOBILE = `(min-width: ${size.minmobile})`;
export const MOBILE = `(min-width: ${size.mobile})`;
export const TABLET = `(min-width: ${size.tablet})`;
export const LAPTOP = `(min-width: ${size.laptop})`;
export const DESKTOP = `(min-width: ${size.desktop})`;
export const PULLWIDE = `(min-width: ${size.pullwide})`;

// @media (min-width: 1300px)
// .container {
//     max-width: 1200px;
// }
// @media (min-width: 1200px)
// .container {
//     max-width: 1140px;
// }
// @media (min-width: 992px)
// .container {
//     max-width: 960px;
// }
// @media (min-width: 768px)
// .container {
//     max-width: 720px;
// }
// @media (min-width: 576px)
// .container {
//     max-width: 540px;
// }
