import React from "react";
import styled from "styled-components";

import { MOBILE, TABLET } from "styles/MediaQuery";

import backGImage from "../images/TestImage/str.jpg";
import main_Image from "../images/useImage/에코메인.jpg";

import Slick from "./MainSlick";
import { RiYoutubeLine } from "react-icons/ri";
const Section_Slider = () => {
  return (
    <>
      <SectionWarp>
        <Slick imageSrc={main_Image} />

        {/* <SectionSlider>
          <CollSlick>
            <DataSlick>
              <div aria-live="polite" className="slick-list draggable">
                <div className="slick-track" role="listbox">
                  <div
                    className="item-slick full-height-with-header slick-slide slick-current slick-active"
                    data-slick-index="0"
                    aria-hidden="false"
                    tabindex="-1"
                    aria-describedby="slick-slide00"
                  >
                    <div className="content-slide">
                      <div
                        className="subtitle-slide animated slideInDown"
                        data-appear="slideInDown"
                        data-delay="800"
                      >
                        Save the Reef
                      </div>

                      <h2
                        className="title-slide animated slideInUp"
                        data-appear="slideInUp"
                        data-delay="0"
                      >
                        Join the movement
                      </h2>

                      <div
                        className="buttons-slide animated zoomIn"
                        data-appear="zoomIn"
                        data-delay="1600"
                      >
                        <a
                          href="#charities"
                          className="hun-button size-normal style-border-light shape-round"
                          tabindex="0"
                        >
                          Learn More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </DataSlick>

            <div className="dots-slick"></div>
          </CollSlick>
  */}
        <SocialSlider>
          <a href="https://www.youtube.com/@bdca7404" className="item-social">
            <RiYoutubeLine size={40} color="#FFF" />
          </a>
        </SocialSlider>
      </SectionWarp>
    </>
  );
};

export default Section_Slider;

const SectionWarp = styled.div`
  background: #edfdff;
  position: relative;
  z-index: 1;
  opacity: 1.2;
`;
const SectionSlider = styled.div`
  clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 50% 100%, 0% 85%);
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 50% 100%, 0% 85%);
  position: relative;
`;

const CollSlick = styled.div`
  position: relative;
  margin: 0px;
  padding: 0px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
`;

const SocialSlider = styled.div`
  left: 0px;
  position: absolute;
  height: 100%;
  z-index: 999;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    font-size: 17px;
    line-height: 1;
    color: #ccc;
    padding: 5px;
    margin: 8px 5px;
    opacity: 0.8;
    transition: all 0.3s;
  }

  @media ${TABLET} {
    left: 10px;
  }
`;
