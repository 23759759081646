import React from 'react'
import styled from 'styled-components';

const Modal_Slide_6 = () => {
  return (
    <>
     <Wrapper>
      <Heading>제6장 재산과 회계</Heading>

      <Subheading>제26조(재산의 구분)</Subheading>
      <Paragraph>
        ① 법인의 재산은 다음과 같이 기본재산과 운영재산으로 구분한다.
        <br />
        ② 기본재산은 법인의 목적사업 수행에 관계되는 부동산 또는 동산으로서 법인 설립 시 그 설립자가 출연한 재산과 이사회에서 기본재산으로 정한 재산으로 하며 그 목록은 붙임과 같다.
        <br />
        ③ 운영재산은 기본재산 이외의 재산으로 한다.
      </Paragraph>

      <Subheading>제27조(재산의 관리)</Subheading>
      <Paragraph>
        ① 법인의 기본재산을 매도, 증여, 임대, 교환하거나 담보 제공 또는 용도 등을 변경하고자 할 때 또는 의무의 부담이나 권리를 포기하고자 할 때는 이사회와 총회의 의결을 거쳐야 한다.
        <br />
        ② 기본재산의 변경에 관하여는 정관변경에 관한 규정을 준용한다.
      </Paragraph>

      <Subheading>제28조(재원)</Subheading>
      <Paragraph>
        법인의 유지 및 운영에 필요한 경비의 재원은 다음 각 호와 같다.
      </Paragraph>
      <List>
        <ListItem>1. 회비</ListItem>
        <ListItem>2. 각종 기부금</ListItem>
        <ListItem>3. 기본재산으로부터 생기는 과실금</ListItem>
        <ListItem>4. 기타</ListItem>
      </List>

      
    </Wrapper>
    
    </>
  )
}

export default Modal_Slide_6


const Wrapper = styled.div`
  font-family: Arial, sans-serif;
  padding: 1vh;
  height: 95%;
`;

const Heading = styled.h2`
  font-size: 1.5em;
  font-weight: bold;
  padding: 3vh 0  4vh 0;
  
`;
const Subheading = styled.h3`
font-weight: bold;
text-align: left;
  font-size: 1em;
  
`;
const Paragraph = styled.p`
text-align: left;
  margin-bottom: 1%;
`;


const List = styled.ul`
  margin-bottom: 0.3em;
`;

const ListItem = styled.li`

font-size: 0.8em;
  text-align: left;
  list-style-type: none;
`;