import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import styled from "styled-components";

import React, { useState } from "react";
import GlobalStyles from "./styles/GlobalStyles";
import Main from "Page/Main";

function App() {
  const [type, setType] = useState(0);

  return (
    <>
      <BrowserRouter>
        {/* 글로벌 css 적용 */}
        <GlobalStyles />

        <Routes>
          <Route index path="/" element={<Main />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

const WarpPage = styled.div`
  position: relative;
  opacity: 0;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  font-family: "Yun", sans-serif;
  font-weight: 400;
  color: #666;
  line-height: 1.8;
  height: 100%;
  height: 100%;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
`;

const HeaderLine = styled.div`
  position: absolute;
  z-index: 200;
  width: 100%;
  top: 0;
  left: 0;
`;
const ContainerLine = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
`;
const WpHeader = styled.div`
  display: flex;
  align-items: center;
  padding-top: 35px;
`;

const HContentLine = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;
const LogoHeader = styled.div`
  text-align: center;
`;

const HNavigation = styled.nav`
  padding-top: 11px;
`;

const HNavigation_ul = styled.ul`
  padding-top: 20px;
  text-align: center;
`;

const HNavigation_li = styled.li`
  position: relative;
  display: inline-block;
`;

const HNavigation_p = styled.p`
  text-transform: uppercase;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.8;
  color: #fff;
  padding: 0px 24px 20px 24px;
  position: relative;
  //하단은 번외
  text-decoration: none;
  background-color: transparent;
`;

const HGLeftSide = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

const HGRightSide = styled.div`
  justify-content: flex-end;
`;
