import Footers from "layout/Footers";
import Headers from "layout/Headers";
import SectionHelper from "components/Section_Helper";
import SectionInfo from "components/Section_Info";
import SectionLast from "components/Section_Last";
import SectionSlider from "components/Section_Slider";
import SectionStory from "components/Section_Story";
import SectionVideo from "components/Section_Video";
import React from "react";
import styled from "styled-components";
import SectionCarousel from "components/Section_Carousel";
import SectionMiniImage from "components/Section_MiniImage";
import LineSection_1 from "components/LineSection_1";
import LineSection_2 from "components/LineSection_2";
import LineSection_3 from "components/LineSection_3";
import LineSection_4 from "components/LineSection_4";
import LineSection_5 from "components/LineSection_5";
import BGALL from "../images/useImage/BG_ALL.png";
import { DESKTOP, LAPTOP } from "styles/MediaQuery";

import { AiOutlineUserAdd } from "react-icons/ai";

import { Ri24HoursFill, RiYoutubeLine } from "react-icons/ri";
import { BsInstagram } from "react-icons/bs";
import { BsFillTelephoneFill } from "react-icons/bs";
import { AiFillCaretUp } from "react-icons/ai";
import { Link } from "react-scroll";

const Main = () => {
  return (
    <>
      <MainWarp>
        <Headers />
        <LineSection_1 />
        <LineSection_2 />
        <LineSection_3 />
        <LineSection_4 />
        <LineSection_5 />
        <SectionHelper />
        {/* <SectionSlider />
        <SectionVideo />
        <SectionStory /> */}
        {/* <SectionCarousel /> */}
        {/* <SectionInfo />
        <SectionMiniImage />
        <SectionLast />
        <SectionHelper /> */}
        <FixedBar>
          <ul>

            <li>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSesxdVWtAKm_IyVLXc-vIssPwYGoMfBLcY1rYTKYnPKo7v06Q/viewform" target="_blank">
                <AiOutlineUserAdd color="#fff" size={30} />
              </a>
            </li>

            <li>
              <a href="https://www.youtube.com/@bdca7404" target="_blank">
                <RiYoutubeLine color="#fff" size={45} />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/saengdasoo/" target="_blank">
                <BsInstagram color="#fff" size={35} />
              </a>
            </li>
            <li>
              <a href="tel:043-217-7404">
                <BsFillTelephoneFill color="#fff" size={32} />
              </a>
            </li>

            <li>
              <Link to="section_Top" spy={true} smooth={true}>
                <AiFillCaretUp color="#fff" size={32} />
              </Link>
            </li>




          </ul>
          {/* <li><FiMail color="#fff" size={35} />  </li> */}
        </FixedBar>
        <FixedMBar>
          <ul>

            <li>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSesxdVWtAKm_IyVLXc-vIssPwYGoMfBLcY1rYTKYnPKo7v06Q/viewform" target="_blank">
                <AiOutlineUserAdd color="#fff" size={30} />
              </a>
            </li>


            <li>
              <a href="https://www.youtube.com/@bdca7404" target="_blank">
                <RiYoutubeLine color="#fff" size={30} />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/saengdasoo/" target="_blank">
                <BsInstagram color="#fff" size={25} />
              </a>
            </li>
            <li>
              <a href="tel:043-217-7404">
                <BsFillTelephoneFill color="#fff" size={25} />
              </a>
            </li>

            <li>
              <Link to="section_Top" spy={true} smooth={true}>
                <AiFillCaretUp color="#fff" size={32} />
              </Link>
            </li>


          </ul>
          {/* <li><FiMail color="#fff" size={35} />  </li> */}
        </FixedMBar>
      </MainWarp>
      {/* <Footers /> */}
    </>
  );
};

export default Main;

const MainWarp = styled.div`
  background-image: url(${BGALL});
  background-size: cover;
  overflow-x: hidden;
  zoom: 1;
  animation-duration: 1500ms;
  opacity: 1;
  position: relative;
`;

const FixedBar = styled.div`
  display: none;
  @media ${DESKTOP} {
    display: block;
    z-index: 9;
    position: fixed;
    top: 25em;
    right: 4em;
    list-style: none;
    color: #fff;
    align-items: center;
    height: 5em;
    width: 5em;
    ul {
      list-style: none;
    }
    li {
      margin: 1em 0;
    }
  }
`;

const FixedMBar = styled.div`
  z-index: 9;
  position: fixed;
  top: 25em;
  right: 0em;
  list-style: none;
  color: #fff;
  align-items: center;
  height: 5em;
  width: 5em;
  ul {
    list-style: none;
  }
  li {
    margin: 1em 0;
  }
  @media ${DESKTOP} {
    display: none;
  }
`;
