import { Modal } from "components/Modal";
import { Modal_Mob } from "components/Modal_Mob";
import React, { useContext, useState } from "react";
import { Link } from "react-scroll";
import styled from "styled-components";
import { MyContext } from "./MyContext";
const Nav = ({ navbarLinks, NavSet, ModalSet }) => {

  // const [isOpen, setIsOpen] = useState(false);

  const { isOpen_M, setIsOpen_M } = useContext(MyContext);
  // const ModalSet2 = () => {
  //   if (isOpen) {
  //     console.log("click1");
  //     setIsOpen(false);
  //   } else {
  //     setIsOpen(true);
  //     console.log("click22");
  //   }
  // };
  // const handleCloseModal = () => {
  //   // setIsOpen(prevState => !prevState);
  //   setIsOpen(false);
  // };



  return (
    <>
      <MainUl>
        <LiLine>
          <Link to="section1" spy={true} smooth={true}>
            <h3 onClick={NavSet}>유튜브</h3>
          </Link>
        </LiLine>
        <LiLine>
          <Link to="section2" spy={true} smooth={true}>
            <h3 onClick={NavSet}>협회소개</h3>
          </Link>
        </LiLine>
        <LiLine>
          <Link to="section3" spy={true} smooth={true}>
            <h3 onClick={NavSet}>사업분야</h3>
          </Link>
        </LiLine>
        <LiLine>
          <Link to="section4" spy={true} smooth={true}>
            <h3 onClick={NavSet}>소개</h3>
          </Link>
        </LiLine>
        <LiLine>
          {/* <span onClick={(event) => { event.stopPropagation(); ModalSet2(); }}>
            협회 정관
          </span> */}
          <span onClick={(event) => { setIsOpen_M(true) }}>
            협회 정관
          </span>

        </LiLine>
        <LiLine>
          <span onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSesxdVWtAKm_IyVLXc-vIssPwYGoMfBLcY1rYTKYnPKo7v06Q/viewform', '_blank')}>회원가입</span>

        </LiLine>



      </MainUl>

    </>
  );
};

export default Nav;

const MainUl = styled.ul`

`


const LiLine = styled.li`
  h3 {
    font-size: 1.5vh;
    font-weight: bold;
    line-height: 6vh;
    text-align: center;
    

  }
  span{
    font-weight: bold;
    line-height: 7vh;
    cursor: pointer;

  }
`;
