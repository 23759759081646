import React from 'react'
import styled from 'styled-components';

const Modal_Slide_4 = () => {
    return (
        <>
            <Wrapper>
                <Heading>제4장 총 회</Heading>

                <Subheading>제15조(총회의 구성)</Subheading>
                <Paragraph>① 총회는 본회의 최고의결기관이며 회원으로 구성한다.</Paragraph>

                <Subheading>제16조(총회의 구분과 소집)</Subheading>
                <Paragraph>① 총회는 정기총회와 임시총회로 구분하며, 회장이 소집한다.</Paragraph>
                <Paragraph>② 정기총회는 매 회계연도 개시 3개월 전, 후까지 소집하며, 임시총회는 회장이 필요하다고 인정할 때에 소집한다.</Paragraph>
                <Paragraph>③ 총회의 소집은 회장이 회의안건, 일시, 장소 등을 명기하여 회의 개시 7일 전까지 문서, 문자메세지, email, sns 등 (발송 사실 확인이 되는 온라인 방법 포함)의 방법으로 각 회원에게 통지하여야 한다.</Paragraph>

                <Subheading>제17조(총회소집의 특례)</Subheading>
                <Paragraph>① 회장은 다음 각 호의 어느 하나에 해당하는 소집요구가 있을 때에는 그 소집 요구일로부터 20일 이내에 총회를 소집하여야 한다.</Paragraph>
                <List>
                    <ListItem>1. 재적 이사 과반수가 회의의 목적을 제시하여 소집을 요구한 때</ListItem>
                    <ListItem>2. 제14조 제3항 제4호의 규정에 따라 감사가 소집을 요구한 때</ListItem>
                    <ListItem>3. 재적 회원 3분의 1 이상이 회의의 목적을 제시하여 소집을 요구한 때</ListItem>
                </List>
                <Paragraph>② 총회 소집권자가 궐위되거나 이를 기피함으로써 7일 이상 총회소집이 불가능한 때에는 재적 이사 과반수 또는 재적 회원 3분의 1 이상의 찬성으로 총회를 소집할 수 있다.</Paragraph>
                <Paragraph>③ 제2항의 규정에 따른 총회는 출석 이사 중 최연장자의 사회아래 그 의장을 선출한다.</Paragraph>

               
            </Wrapper>
        </>
    )
}

export default Modal_Slide_4

const Wrapper = styled.div`
  font-family: Arial, sans-serif;
  padding: 1vh;
  height: 95%;
`;

const Heading = styled.h2`
  font-size: 1.5em;
  font-weight: bold;
  padding: 3vh 0  4vh 0;
  
`;
const Subheading = styled.h3`
font-weight: bold;
text-align: left;
  font-size: 1em;
  
`;
const Paragraph = styled.p`
text-align: left;
  margin-bottom: 1%;
`;


const List = styled.ul`
  margin-bottom: 10px;
`;

const ListItem = styled.li`

font-size: 0.8em;
  margin-bottom: 10px;
text-align: left;
  list-style-type: none;
`;
const ModalContent = styled.div`
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 5px;
`;