import React from "react";
import styled from "styled-components";
import { DESKTOP, LAPTOP, MOBILE, TABLET } from "styles/MediaQuery";
import aboutright from "../images/TestImage/about-right.jpg";
import aboutleft from "../images/TestImage/about-left.jpg";
import aboutbg from "../images/TestImage/about-bg.jpg";
import aboutbg_main from "../images/useImage/slick_텐트.jpg";
import aboutright_main from "../images/useImage/계곡폭포.jpg";
import aboutleft_main from "../images/useImage/slick_개울.jpg";
import waveline02 from "../images/TestImage/wave-line-02.png";
const Section_Story = () => {
  return (
    <>
      <StoryWrap>
        <StoryContainer>
          <ContanierLayout>
            <PicTop>
              <img src={aboutright_main} alt="IMG" />
            </PicTop>

            <TextData>
              <div className="heading-element">
                <h3 className="title-element">협회 소개글</h3>
                <br></br>
                {/* <LineSet>
                  <LineIcon>
                    <i>
                      <img src="" alt="" />
                    </i>
                  </LineIcon>
                  <LineText>BDCA</LineText>
                </LineSet> */}
                {/* <img className="line-heading" src={waveline02} alt="IMG" /> */}
              </div>

              <div className="content-element">
                <b>”창백한 푸른 점“</b>에 살고있는 모든 생물은<br></br> 저마다의
                역할과 존재의 이유가 있습니다. <br></br>우리 인간 역시 그런 모든
                생물과 지구라는 터전을<br></br> 공유하고 있는 하나의 생물 종
                집단에 불과하다고 생각합니다. 모든 생물과 생명체들은 더없이
                소중하고 그 존재의 가치가 높다는 생각을 실질적인 행동으로
                실천하고 생물 다양성을 회복, 유지, 관리하며 지구의 모든
                생물들과의 조화로운 발전을 고민하고 행동하는 단체가 되겠습니다.
                <br /> ”창백한 푸른 점“에 사는 모든 이웃을 아끼고
                사랑하겠습니다.
                <br /> 지구의 80억 인구 그리고 또다른 지구의 주인인 모든 생물들
                생물다양성을 기억해 주시고 응원해 주세요 지구의 모든 생물들과
                공존의 길을 함께 하겠습니다
                <RightDiv>생물다양성보전협회 임직원 일동</RightDiv>
              </div>
            </TextData>

            <PicBottom>
              <img src={aboutleft_main} alt="IMG" />
            </PicBottom>
          </ContanierLayout>
        </StoryContainer>
      </StoryWrap>
    </>
  );
};

export default Section_Story;

const StoryWrap = styled.div`
  background-image: url(${aboutbg_main});
  background-position: center;
  background-size: cover;
  padding-top: calc(100vh / 100 * 20 + 100px);
  padding-bottom: 100px;
  background-attachment: inherit;
  margin-top: calc(100vh / 100 * 20 * (-1));
  position: relative;

  z-index: 0;

  ::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    background-color: rgb(237, 253, 255);
    opacity: 0.9;
  }

  @media ${MOBILE} {
  }

  @media ${TABLET} {
  }
  @media ${LAPTOP} {
  }
  @media ${DESKTOP} {
    margin-top: calc(100vh / 100 * 20 * (-1));
    position: relative;
    z-index: 0;
    padding-top: calc(100vh / 100 * 20 + 50px);
    padding-bottom: 160px;
  }
`;

const StoryContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
  @media ${DESKTOP} {
    max-width: 1200px;
  }
`;

const ContanierLayout = styled.div`
  position: relative;
  z-index: 0;
`;

const PicTop = styled.div`
  display: none;
  width: 38%;
  position: relative;
  right: 0;
  margin-right: 0;
  margin-left: auto;
  transform: translate(0, 30%);
  z-index: 1;

  @media ${MOBILE} {
  }

  @media ${TABLET} {
  }

  @media ${DESKTOP} {
    display: block;
    width: 22.7%;
    position: absolute;
    z-index: -1;
    right: 5.8%;
    top: 0;
    transform: translateY(-21%);
  }
`;

const TextData = styled.div`
  width: 100%;
  padding: 90px 30px 80px 30px;
  background-color: #fff;
  margin-right: auto;
  margin-left: auto;
  box-shadow: 0px 0px 65px 0px rgba(168, 82, 82, 0.26);
  position: relative;

  b {
    font-weight: bold;
    font-size: 1.3em;
  }
  h3 {
    font-size: 1.8em;
  }
  ::before {
    content: "";
    display: block;
    width: calc(100% - 28px);
    height: calc(100% - 28px);
    top: 14px;
    left: 14px;
    position: absolute;
    border: 1px solid orange;
    pointer-events: none;
  }
  @media ${DESKTOP} {
    background-color: #fff;
    width: 50%;
    margin-right: auto;
    margin-left: auto;
    padding: 130px 50px 120px 50px;
    box-shadow: 0px 0px 65px 0px rgba(168, 82, 82, 0.26);
    position: relative;
  }
`;

const HeadingData = styled.div`
  text-align: center;
  margin-bottom: 50px;
  font-size: 33px;

  h3 {
    font-weight: 400;
    line-height: 1.1;
    color: #020041;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-family: "Yun", sans-serif;
    margin-bottom: 0.75rem;
  }
`;

const PicBottom = styled.div`
  display: none;
  width: 55%;
  position: relative;
  left: 0;
  margin-left: 0;
  margin-right: auto;
  transform: translate(0, -18%);
  z-index: -1;
  bottom: 0;

  @media ${DESKTOP} {
    display: block;
    width: 31.1%;
    position: absolute;
    z-index: -1;
    left: 2.5%;
    bottom: 0;
    transform: translateY(15.8%);
  }
`;

const RightDiv = styled.div`
  padding-top: 10%;

  text-align: right;
  font-size: 1.2em;
  font-weight: bold;
`;
